import { ReactElement, useState } from 'react'
import { SiteButton } from '../controls/SiteButton'
import { useAuth0, User } from '@auth0/auth0-react'
import { resendVerificationEmail } from '../../services/accounts'

export function EmailVerification(usr: User): ReactElement {
  const [isResent, setIsResent] = useState(false)

  const { getAccessTokenSilently } = useAuth0()
  const handleResend = () => {
    setIsResent(false)
    getAccessTokenSilently().then(async (token) => {
      resendVerificationEmail(usr.usr.sub, token)
      setTimeout(() => {
        setIsResent(true)
      }, 1500)
    })
  }

  return (
    <div className="flex justify-center items-center !h-auto mt-10">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center">
        <h2 className="text-2xl font-semibold mb-4">Email Verification Required</h2>
        <p className="mb-4 text-credsPrimaryBlue-100 font-medium">
          Please check your inbox for an email from support@credsnow.com
        </p>
        <p className="mb-8 text-PrimaryDarkBlue-100">
          To complete your account setup click on the 'Verify Your Account' button. Once verified, refresh this page to
          get started.
        </p>
        <p className="mb-2 text-PrimaryDarkBlue-100 text-sm">Please check your spam folder before resending email.</p>
        <SiteButton onClick={handleResend} id="" label="Resend Email" />
        <div>
          {isResent && (
            <div className="text-PrimaryDarkBlue-100 text-sm mt-4 bg-[#d2fda1] p-7 rounded-[8px]">
              Verification email has been <b>re-sent</b>. Please check your inbox and spam folder.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
