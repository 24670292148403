import { ReactElement } from 'react'
import { AdminLogo } from './AdminLogo'
import { AdminProfile } from './AdminProfile'

export const AdminHeader = (): ReactElement => {
  return (
    <div className="h-20 flex items-center justify-between w-full">
      <AdminLogo />
      <div className="flex justify-center items-center mt-[10px] px-10">
        <AdminProfile />
      </div>
    </div>
  )
}
