import { ReactElement } from 'react'
import { EventLayout } from '../../components/layout/EventLayout'
import { EventHeader } from '../../components/layout/EventHeader'

export default function EventKiosk(): ReactElement {
  return (
    <EventLayout>
      <div className="container mx-auto">
        <EventHeader pageName="Kiosk" />
      </div>
    </EventLayout>
  )
}
