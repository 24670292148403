// import { Button, Input, Skeleton, Space, Table, Tag } from 'antd'
import { Skeleton, Table, Tag } from 'antd'
import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../../components/shared/LoadingSpinner'
import { errorHandler, insertSpaceCamelCase, userStatusColor } from '../../../utils/helper'
import { useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import { fetchUsersList } from '../../../services/accounts'
import { useAuth0 } from '@auth0/auth0-react'
import { Roles, UserStatusEnum } from '../../../constants/common'
import { AccountUser } from '../../../models/manager/accountUser'

interface Props {
  accountId: number
}

export default function AdminAccountUsers({ accountId }: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [accountUserData, setAccountUserData] = useState<AccountUser[]>([])

  const columns: ColumnsType<AccountUser> = [
    {
      key: 'User',
      title: 'User',
      dataIndex: '',
      sorter: (a, b) => a.User?.LastName?.localeCompare(b.User?.LastName ?? '') ?? 1,
      render: (record: AccountUser) => (
        <div className="flex items-center gap-2">
          <div className="flex flex-col pl-2 pr-2">
            {record.User?.FirstName} {record.User?.LastName}
          </div>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: '',
      key: 'Email',
      sorter: (a, b) => (a.User != null ? a.User.Email.localeCompare(b.User?.Email ?? '') : 1),
      render: (record: AccountUser) => <span className="pl-2 pr-2">{record.User?.Email ?? ''}</span>,
    },
    {
      title: 'Role',
      dataIndex: '',
      key: 'role',
      sorter: (a, b) => (a.RoleId || 0) - (b.RoleId || 0),
      render: (record: AccountUser) => <span>{insertSpaceCamelCase(Roles[record.RoleId || 3])}</span>,
    },
    {
      title: 'Status',
      dataIndex: '',
      key: 'status',
      sorter: (a, b) => (a.UserStatusId || 0) - (b.UserStatusId || 0),
      render: (record: AccountUser) => (
        <div className="flex justify-center pl-2 pr-2 pt-1 pb-1">
          <Tag style={userStatusColor(record.UserStatusId || 3)}>{UserStatusEnum[record.UserStatusId || 3]}</Tag>
        </div>
      ),
      filters: [
        {
          text: 'Active',
          value: UserStatusEnum.Active,
        },
        {
          text: 'Disabled',
          value: UserStatusEnum.Disabled,
        },
        {
          text: 'Invited',
          value: UserStatusEnum.Invited,
        },
      ],
      onFilter: (value, record) => record?.UserStatusId === value,
    },
  ]

  const onRowSelected = (user: AccountUser, event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    event?.stopPropagation()
    navigate(`/user-detail`, { state: { userId: user.UserId } })
  }

  useEffect(() => {
    getAccessTokenSilently().then(async (token) => {
      fetchUsersList(accountId, token)
        .then((data) => {
          data.sort((a, b) => {
            if (a.User?.LastName && b.User?.LastName) {
              return a.User.LastName.localeCompare(b.User?.LastName)
            } else {
              return 1
            }
          })
          return data
        })
        .then(setAccountUserData)
        .catch(errorHandler)
        .finally(() => setIsLoading(false))
    })
  }, [])

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      {isLoading && <LoadingSpinner isLoading={isLoading} label="Loading..." />}
      <div className="flex-col flex-wrap">
        <div className="adminContainerRow">
          <div className="adminContainer">
            <div id="adminAccountUsers" className="mt-1">
              {isLoading ? (
                <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
              ) : (
                <Table
                  id="view-users"
                  pagination={{
                    pageSizeOptions: ['10', '20', '30', '50'],
                    showSizeChanger: true,
                  }}
                  bordered
                  className="adminTableRow"
                  rowKey={(record) => record.UserId.toString()}
                  columns={columns}
                  size="small"
                  onRow={(record) => {
                    return {
                      onClick: (e) => {
                        onRowSelected(record, e)
                      },
                    }
                  }}
                  dataSource={accountUserData}
                  onHeaderRow={() => ({
                    className:
                      'text-[12px] !text-[#7E7E7E] not-italic !font-normal leading-[20px] tracking-wide text-left !bg-[white]',
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}
