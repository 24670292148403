import { Skeleton, Table } from 'antd'
import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../../components/shared/LoadingSpinner'
import { errorHandler } from '../../../utils/helper'
import { useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { fetchRecordSets } from '../../../services/records'
import { RecordSet } from '../../../models/manager/recordset'
import { useAuth0 } from '@auth0/auth0-react'

interface Props {
  accountId: number
}

export default function AdminAccountLists({ accountId }: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [filteredDataSource, setFilteredDataSource] = useState<RecordSet[]>([])

  const columns: ColumnsType<RecordSet> = [
    {
      key: 'created',
      title: 'Created',
      dataIndex: 'CreatedAt',
      render: (date: number) => {
        return <div className="pr-2 pl-2 flex flex-nowrap min-w-[100px]">{dayjs(date).format('YYYY-MM-DD')}</div>
      },
      sorter: (a, b) => (dayjs(a.CreatedAt).isBefore(b.CreatedAt) ? -1 : 1),
      width: 100,
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'Name',
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (listName) => {
        return (
          <div className="flex flex-nowrap justify-start items-center gap-1">
            <div className="flex-[1] px-[8px] py-px gap-[4px] cursor-pointer flex-nowrap">
              <div className="text-[#000000] not-italic font-normal pr-2 pl-2 overflow-hidden overflow-ellipsis w-[fit-content] whitespace-nowrap max-w-[23ch]">
                {listName}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'Description',
      sorter: (a, b) => a.Description.localeCompare(b.Description),
      render: (description) => {
        return (
          <div className="flex flex-nowrap justify-start items-center gap-1">
            <div className="flex-[1] px-[8px] py-px gap-[4px] cursor-pointer flex-nowrap">
              <div className="text-[#000000] not-italic font-normal pr-2 pl-2 overflow-hidden overflow-ellipsis w-[fit-content] whitespace-nowrap max-w-[25ch]">
                {description}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      key: 'records',
      title: 'Records',
      dataIndex: 'RecordCount',
      sorter: (a, b) => (a.RecordCount || 0) - (b.RecordCount || 0),
      render: (records: number) => {
        return <div className="pr-2 pl-2 text-center">{records}</div>
      },
    },
    {
      key: 'updated',
      title: 'Modified',
      dataIndex: 'UpdatedAt',
      render: (date: number) => {
        return (
          <div className="pr-2 pl-2 pt-1 pb-1 flex flex-nowrap min-w-[100px]">{dayjs(date).format('YYYY-MM-DD')}</div>
        )
      },
      sorter: (a, b) => (dayjs(a.UpdatedAt).isBefore(b.UpdatedAt) ? -1 : 1),
      width: 100,
    },
  ]

  const onRowSelected = (recordSet: RecordSet, event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    event?.stopPropagation()
    navigate('/manage-list', { state: { recordSetId: recordSet.Id, recordSetName: recordSet.Name } })
  }

  useEffect(() => {
    getAccessTokenSilently().then(async (token) => {
      fetchRecordSets(accountId, token)
        .then((data) => {
          data.sort((a, b) => (dayjs(a.UpdatedAt).isBefore(b.UpdatedAt) ? 1 : -1))
          setFilteredDataSource(data)
        })
        .catch(errorHandler)
        .finally(() => setIsLoading(false))
    })
  }, [])

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      {isLoading && <LoadingSpinner isLoading={isLoading} label="Loading..." />}
      <div className="flex-col flex-wrap">
        <div className="adminContainerRow">
          <div className="adminContainer">
            <div id="adminAccountTemplates" className="mt-1">
              {isLoading ? (
                <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
              ) : (
                <Table<RecordSet>
                  id="view-templates"
                  pagination={{
                    pageSizeOptions: ['10', '20', '30', '50'],
                    showSizeChanger: true,
                  }}
                  bordered
                  className="adminTableRow"
                  rowKey={(record) => record.Id}
                  columns={columns}
                  size="small"
                  dataSource={filteredDataSource}
                  onRow={(record) => {
                    return {
                      onClick: (e) => {
                        onRowSelected(record, e)
                      },
                    }
                  }}
                  onHeaderRow={() => ({
                    className:
                      'text-[12px] !text-[#7E7E7E] not-italic !font-normal leading-[20px] tracking-wide text-left !bg-[white]',
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}
