import { MenuProps } from 'antd'
import { ProfileData } from '../models/profileData'
import { checkPermission } from './permissions'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Roles } from '../constants/common'

type MenuItem = Required<MenuProps>['items'][number]

export type ExtendedMenuItem = MenuItem & {
  forImpersonation?: boolean
  roles?: string[]
  userRoles?: Roles[]
  permissions?: number[]
  children?: ExtendedMenuItem[]
}

export const filterMenuItems = (items: ExtendedMenuItem[], profile: ProfileData): ExtendedMenuItem[] => {
  return items
    .filter((item) => !item.roles || item.roles.some((role) => profile.AuthRoles.includes(role)))
    .filter((item) => !item.userRoles || item.userRoles.some((role) => profile.Role == role))
    .filter((item) => !item.forImpersonation || profile.IsImpersonating)
    .map((item) => {
      const newItem = {
        ...item,
        disabled:
          item.permissions && !item.permissions.some((permission) => checkPermission(permission, profile.Permissions)),
      }
      if (item.children) {
        newItem.children = filterMenuItems(item.children, profile)
      }
      return newItem
    })
}

//TODO: find out a type for the renderLabel function

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const renderLabel = (item: any): ReactNode => {
  if (item.disabled) {
    if (typeof item.label === 'object' && 'props' in item.label) {
      return item.label.props.children.toString()
    }
  }
  if (React.isValidElement(item.label)) {
    return item.label
  }
  if (typeof item.label === 'string') {
    return item.label.startsWith('<Link') ? <Link to={item.key.toString()}>{item.label}</Link> : item.label
  }

  return null
}

export const transformMenuItem = (item: ExtendedMenuItem): ExtendedMenuItem => {
  const transformedItem = { ...item, label: renderLabel(item) }

  if (item.children && item.children.length > 0) {
    transformedItem.children = item.children.map(transformMenuItem)
  }

  return transformedItem
}
