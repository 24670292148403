import { ReactElement, ReactNode } from 'react'
import { Button as AntButton } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'

export enum SiteButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

interface Props {
  label: string
  id: string
  variant?: SiteButtonVariant
  disabled?: boolean
  type?: 'button' | 'submit'
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void
  style?: React.CSSProperties
  size?: SizeType
  icon?: ReactNode
}

export function SiteButton({
  label,
  id,
  variant = SiteButtonVariant.Primary,
  type = 'button',
  disabled = false,
  onClick,
  style,
  size,
  icon,
}: Props): ReactElement {
  const buttonClasses = `${
    variant === SiteButtonVariant.Primary
      ? 'text-white hover:!text-white focus:!text-white bg-credsPrimaryBlue-100 hover:!bg-PrimaryDarkBlue-100 m-3 px-4 inline-flex items-center '
      : 'rounded-md border border-credsPrimaryBlue-100 bg-PrimaryBgWhite-100 shadow-sm hover:!bg-PrimaryDarkBlue-100  hover:!text-white  m-3 px-4 inline-flex items-center'
  }`
  return (
    <AntButton
      icon={icon}
      size={size}
      style={style}
      className={buttonClasses}
      onClick={onClick}
      id={id}
      disabled={disabled}
      htmlType={type}
    >
      {label}
    </AntButton>
  )
}
