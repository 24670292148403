import { Spin } from 'antd'
import { ReactElement } from 'react'

interface Props {
  isLoading: boolean
  label?: string
}

export function LoadingSpinner({ isLoading, label }: Props): ReactElement {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-PrimaryBgWhite-100 opacity-70 flex justify-center items-center z-50">
        <Spin
          className="ant-spin-dot !text-credsPrimaryBlue-100 font-bold"
          style={{ zoom: '200%' }}
          spinning={isLoading}
          size="large"
        >
          <div className="pt-[100px]">&nbsp;</div>
          <div className="font-black">{label}</div>
        </Spin>
      </div>
    </>
  )
}
