import { Transaction } from '../models/manager/transaction'
import { apiRequest } from '../utils/axios'

export async function fetchTransactions(accountId: number, token: string): Promise<Transaction[]> {
  return apiRequest('GET', `/transactions/${accountId}`, token)
}

export async function fetchTransaction(transactionId: number, token: string): Promise<Transaction> {
  return apiRequest('GET', `/transaction/${transactionId}`, token)
}

export async function fetchTransactionByJobId(jobId: number, token: string): Promise<Transaction[]> {
  return apiRequest('GET', `/transactionsForJob/${jobId}`, token)
}

export async function saveTransaction(transactionData: Transaction, token: string): Promise<number> {
  return apiRequest('POST', '/transaction', token, transactionData)
}

export async function getAccountBalance(accountId: number, token: string): Promise<number> {
  return apiRequest('GET', `/transactionBalance/${accountId}`, token)
}

export async function getCardsRemaining(accountId: number, token: string): Promise<number> {
  return apiRequest('GET', `/transactionRemaining/${accountId}`, token)
}
