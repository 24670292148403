import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../components/shared/LoadingSpinner'
import { AdminButton } from '../components/controls/AdminButton'
import { Checkbox, Input, Skeleton, Table } from 'antd'
import { Account } from '../../models/manager/account'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { errorHandler } from '../../utils/helper'
import { PlanType } from '../../constants/common'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { fetchAccounts } from '../../adminModule/services/adminAccount'

const AdminAccountListing = (): ReactElement => {
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [isLoading, setIsLoading] = useState(true)
  const [accountsData, setAccountsData] = useState<Account[]>([])
  const [filteredDataSource, setFilteredDataSource] = useState<Account[]>([])
  const [searchText, setSearchText] = useState('')
  const [showIncomplete, setShowIncomplete] = useState<boolean>(false)

  const onRowSelected = (account: Account, event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    event?.stopPropagation()
    navigate(`/admin/account-detail?accountId=${account.Id}&accountName=${account.Name}`)
  }

  const columns: ColumnsType<Account> = [
    {
      key: 'updated',
      title: 'Created',
      dataIndex: 'CreatedAt',
      render: (date) => {
        return <div className="pr-2 pl-2 pt-1 pb-1 whitespace-nowrap">{dayjs(date).format('YYYY-MM-DD')}</div>
      },
      sorter: (a, b) => (dayjs(a.CreatedAt).isBefore(b.CreatedAt) ? -1 : 1),
    },
    {
      key: 'id',
      title: 'Account Id',
      dataIndex: 'Id',
      width: 120,
      sorter: (a, b) => a.Id - b.Id,
      render: (id) => {
        return <div className="pr-2 pl-2 pt-1 pb-1">{id}</div>
      },
    },
    {
      key: 'name',
      title: 'Account Name',
      dataIndex: 'Name',
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (name) => {
        return <div className="pr-2 pl-2 pt-1 pb-1 font-semibold">{name}</div>
      },
    },
    {
      key: 'IsActive',
      title: 'Active',
      dataIndex: 'IsActive',
      render: (IsActive) => {
        return <div className="pr-2 pl-2 pt-1 pb-1">{IsActive === true ? 'Yes' : 'No'}</div>
      },
      sorter: (a, b) => Number(a.IsActive) - Number(b.IsActive),
    },
    {
      key: 'PlanStartDate',
      title: 'Plan Start Date',
      dataIndex: 'PlanStartDate',
      render: (planStartDate) => {
        return <div className="pr-2 pl-2 pt-1 pb-1 whitespace-nowrap">{planStartDate}</div>
      },
    },
    {
      key: 'PlanId',
      title: 'Plan Id',
      dataIndex: 'PlanId',
      render: (PlanId) => {
        return <div className="pr-2 pl-2 pt-1 pb-1">{PlanType[PlanId]}</div>
      },
      sorter: (a, b) => PlanType[a.PlanId].localeCompare(PlanType[b.PlanId]),
    },
  ]

  const onSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClose = () => {
    navigate('/admin/dashboard')
  }

  useEffect(() => {
    getAccessTokenSilently().then(async (token) => {
      fetchAccounts(token)
        .then((data) => {
          data.sort((a, b) => (dayjs(a.Name).isBefore(b.Name) ? 1 : -1))
          setAccountsData(data)
          setFilteredDataSource(data.filter((item) => item.Name.length > 0))
        })
        .catch(errorHandler)
        .finally(() => setIsLoading(false))
    })
  }, [])

  useEffect(() => {
    const lowercasedSearchText = searchText.toLowerCase()

    const filteredData = accountsData
      ?.filter((item) => {
        const isIncomplete = showIncomplete ? true : item.Name.length > 0
        const hasIdMatch = item.Id.toString().includes(lowercasedSearchText)
        const hasNameMatch = item?.Name?.toLowerCase().includes(lowercasedSearchText)
        return isIncomplete && (hasIdMatch || hasNameMatch)
      })
      .sort((a, b) => (dayjs(a.UpdatedAt).isBefore(b.UpdatedAt) ? 1 : -1))
    setFilteredDataSource(filteredData)
  }, [accountsData, searchText])

  useEffect(() => {
    if (!showIncomplete) {
      const filteredData = accountsData.filter((item) => item.Name.length > 0)
      setFilteredDataSource(filteredData)
    } else {
      setFilteredDataSource(accountsData)
    }
  }, [showIncomplete])

  function handleIncompleteViewChange() {
    setShowIncomplete(!showIncomplete)
  }

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      <div className="container mx-auto">
        <div className="flex justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6 my-2 py-2">
          <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Account Listing</h1>
          <AdminButton label={'Close'} onClick={handleClose} />
        </div>
        <div className="flex-col justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6 my-3 h-full py-2">
          <div className="flex justify-center items-center w-full py-2">
            <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Summary</h1>
            <Input.Search
              value={searchText}
              style={{ width: 'auto' }}
              placeholder="Search..."
              onChange={(e) => onSearch(e.target.value)}
              allowClear
            />
          </div>
          <div className="mt-1">
            <div className="adminContainerRow">
              <div className="adminContainer">
                <div className="flex flex-col gap-1 w-full items-end">
                  <Checkbox checked={showIncomplete} onChange={() => handleIncompleteViewChange()}>
                    Include Incomplete
                  </Checkbox>
                </div>
                <div id="adminAccountListing" className="mt-1">
                  {isLoading ? (
                    <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
                  ) : (
                    <Table<Account>
                      pagination={{
                        pageSizeOptions: ['10', '20', '30', '50'],
                        showSizeChanger: true,
                        defaultPageSize: 20,
                      }}
                      bordered
                      className="adminTableRow"
                      rowKey={(record) => record.Id}
                      columns={columns}
                      size="small"
                      onRow={(record) => {
                        return {
                          onClick: (e) => {
                            onRowSelected(record, e)
                          },
                        }
                      }}
                      dataSource={filteredDataSource}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default AdminAccountListing
