import { Link } from 'react-router-dom'
import { AccountPermissions, AuthRoles, Roles } from '../../constants/common'
import { ExtendedMenuItem } from '../../utils/extendedMenu'

export const MenuItems: ExtendedMenuItem[] = [
  {
    label: <Link to="/home">Home</Link>,
    key: 'home',
    style: { fontWeight: 600 },
  },
  {
    label: <Link to="/events">Events</Link>,
    key: 'events',
    style: { fontWeight: 600 },
    roles: [AuthRoles.Onsite],
  },
  {
    label: 'Lists',
    key: 'Lists',
    style: { fontWeight: 600, borderLeft: 'none !important', borderRight: 'none !important' },
    expandIcon: true,
    children: [
      {
        style: { height: '100%' },
        label: (
          <Link to="/lists" className="flex flex-col px-1 py-2">
            <span className="font-semibold text-sm">View Lists</span>
            <span className="text-[#000000A6] text-xs">Manage your list data and records</span>
          </Link>
        ),
        key: '/lists',
      },
      {
        style: { height: '100%' },
        label: (
          <Link to="/create-list" className="flex flex-col px-1 py-2">
            <span className="font-semibold text-sm">Create New List</span>
            <span className="text-[#000000A6] text-xs">New lists for your data personalization</span>
          </Link>
        ),
        key: '/create-list',
        permissions: [AccountPermissions.CanCreateLists],
      },
    ],
  },
  {
    label: 'Templates',
    key: 'Templates',
    style: { fontWeight: 600, borderLeft: 'none !important', borderRight: 'none !important' },
    expandIcon: true,
    children: [
      {
        style: { height: '100%' },
        label: (
          <Link to="/template-library" className="flex flex-col px-1 py-2">
            <span className="font-semibold text-sm">Template Library</span>
            <span className="text-[#000000A6] text-xs">Create, design, and modify badge designs here</span>
          </Link>
        ),
        key: '/template-library',
      },
    ],
  },
  {
    label: 'Printing',
    key: 'Jobs',
    style: { fontWeight: 600, borderLeft: 'none !important', borderRight: 'none !important' },
    expandIcon: true,
    children: [
      {
        style: { height: '100%' },
        label: (
          <Link to="/quick-print" className="flex flex-col px-1 py-2">
            <span className="font-semibold text-sm">Quick Print</span>
            <span className="text-[#000000A6] text-xs">Simple lookup and print of your records</span>
          </Link>
        ),
        key: '/quick-print',
        roles: [AuthRoles.Onsite],
      },
      {
        type: 'divider',
        style: { backgroundColor: '#1D8EF1', marginLeft: '10px', marginRight: '10px' },
        roles: [AuthRoles.Onsite],
      },
      {
        style: { height: '100%' },
        label: (
          <Link to="/job-listing" className="flex flex-col px-1 py-2">
            <span className="font-semibold text-sm">Order Badges</span>
            <span className="text-[#000000A6] text-xs">We print and ship your badge order</span>
          </Link>
        ),
        key: '/job-listing',
      },
    ],
  },
  {
    label: 'Manage',
    key: 'Manage',
    expandIcon: true,
    style: { fontWeight: 600 },
    children: [
      {
        style: { height: '100%' },
        label: (
          <Link to="/manage-address" className="flex flex-col px-1 py-2">
            <span className="font-semibold text-sm">Addresses</span>
            <span className="text-[#000000A6] text-xs">add your shipping addresses for orders</span>
          </Link>
        ),
        key: '/manage-address',
        permissions: [AccountPermissions.CanCreateAddresses],
      },
      {
        style: { height: '100%' },
        label: (
          <Link to="/user-list" className="flex flex-col px-1 py-2">
            <span className="font-semibold text-sm">Users</span>
            <span className="text-[#000000A6] text-xs">Add and manage users on your account</span>
          </Link>
        ),
        key: '/user-list',
        userRoles: [Roles.AccountOwner, Roles.SystemAdmin],
      },
      {
        style: { height: '100%' },
        label: (
          <Link to="/admin" className="flex flex-col px-1 py-2">
            <span className="font-semibold text-sm">Admin</span>
            <span className="text-[#000000A6] text-xs">Access to the admin management module</span>
          </Link>
        ),
        key: '/admin',
        roles: [AuthRoles.Admin],
      },
      {
        type: 'divider',
        style: { backgroundColor: '#1D8EF1', marginLeft: '10px', marginRight: '10px' },
        roles: [AuthRoles.Onsite],
      },
      {
        style: { height: '100%' },
        label: (
          <Link to="/printing" className="flex flex-col px-1 py-2">
            <span className="font-semibold text-sm">Local Printing</span>
            <span className="text-[#000000A6] text-xs">Install and configure the print agent app</span>
          </Link>
        ),
        key: '/printing',
        roles: [AuthRoles.Onsite],
      },
    ],
  },
]
