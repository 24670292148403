import { Button, Modal, Progress, Tag } from 'antd'
import { ReactElement, useState } from 'react'
import { SiteButton, SiteButtonVariant } from '../controls/SiteButton'
import printerImg from '../../assets/images/localPrint/printer.svg'
import { InfoCircleFilled } from '@ant-design/icons'

const PA_INSTALLER_URL = process.env.REACT_APP_PA_INSTALLER_URL

interface Props {
  numberOfAgents?: number
}
export function PrintAgentHeader({ numberOfAgents = 0 }: Props): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [progress, setProgress] = useState(0)

  const handleCancel = () => {
    setIsModalOpen(false)
    setProgress(0)
  }

  const handleDownload = () => {
    setIsModalOpen(true)
    setProgress(0)
    let isDownloading = false
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          setIsModalOpen(false)
          clearInterval(interval)
          if (!isDownloading) {
            isDownloading = true
            triggerFileDownload()
          }
          return 100
        }
        return prevProgress + 20
      })
    }, 500)
  }

  const triggerFileDownload = () => {
    const link = document.createElement('a')
    link.href = PA_INSTALLER_URL ?? ''
    link.download = 'print-agent-setup'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className="flex p-4 border-gray-200 justify-between w-full">
      <div className="flex flex-col w-1/2">
        <div className="flex items-center gap-4">
          <img src={printerImg} alt="Printer" />
          <div>
            <h2 className="text-lg font-semibold">Print Agents</h2>
          </div>
          <Tag color="blue">{numberOfAgents}</Tag>
        </div>
        <span className="text-sm ml-10 text-[#000000A6]">
          The print agent will display here when it’s installed on your remote computer connected to your printer. You
          can print directly from the CredsNow platform to your local printer through the connected print agent.
        </span>
      </div>
      <div className="flex gap-2 items-center p-3 bg-SecondaryLightBlue-100 rounded-md pl-5">
        <span>Install Windows app to print locally</span>
        <SiteButton
          label={'Download Print Agent'}
          id={'download'}
          onClick={handleDownload}
          variant={SiteButtonVariant.Secondary}
        />
      </div>
      <Modal
        title={
          <div className="flex gap-3 items-center">
            <InfoCircleFilled className="text-credsPrimaryBlue-100 text-xl" />
            <span>Downloading Print Agent Install</span>
          </div>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Progress percent={progress} status="active" />
      </Modal>
    </div>
  )
}
