import { ReactElement } from 'react'

interface Props {
  amount: number
}

export function CurrencyFormatter({ amount }: Props): ReactElement {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return <>{formatter.format(amount)}</>
}
