import React, { ReactElement } from 'react'
import { Card } from 'antd'
import { Template } from '../../../models/manager/template'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { TemplateThumbnail } from '../../../components/shared/TemplateThumbnail'

dayjs.extend(relativeTime)
dayjs.extend(utc)

interface Props {
  template: Template
  actions: boolean
  thumbnailLocation: string
  style?: React.CSSProperties
}

export default function AdminTemplateCard({ template, thumbnailLocation, style }: Props): ReactElement {
  const { Id, Name, UpdatedAt } = template

  return (
    <div style={style}>
      <Card
        className="rounded-lg shadow-lg"
        cover={
          <div className="flex flex-col items-center justify-center bg-[rgb(247,_245,_245)] rounded-lg">
            <a>
              <TemplateThumbnail
                mode="grid"
                templateId={Id}
                thumbnailLocation={thumbnailLocation}
                extension="png"
                type={template.TemplateType}
              />
            </a>
          </div>
        }
      >
        <div className="flex items-center justify-between" style={{ zIndex: 100 }}>
          <div className="flex flex-col">
            <span className="w-48 truncate">{Name}</span>
            <div className="text-xs text-gray-500">Last updated {dayjs.utc(UpdatedAt).local().fromNow()}</div>
          </div>
        </div>
      </Card>
    </div>
  )
}
