import { ReactElement } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import AdminDashboard from '../pages/AdminDashboard'
import { AdminLayout } from '../components/layout/AdminLayout'
import AdminAccountListing from '../pages/AdminAccountListing'
import AdminAccountDetail from '../pages/AdminAccountDetail'
import AdminJobListing from '../pages/AdminJobListing'
import AdminJobDetail from '../pages/AdminJobDetail'
import { useProfileContext } from '../../contexts/profileContext'
import { AuthRoles } from '../../constants/common'
import AdminJobLogListing from '../pages/AdminJobLogListing'

export const AdminRoutes = (): ReactElement => {
  const { profile } = useProfileContext()

  if (!profile.AuthRoles.includes(AuthRoles.Admin)) {
    return <Navigate replace to="/home" />
  }

  return (
    <AdminLayout>
      <Routes>
        <Route path="/*" element={<Navigate replace to="/admin/dashboard" />} />
        <Route path="/dashboard" element={<AdminDashboard />} />
        <Route path="/account-listing" element={<AdminAccountListing />} />
        <Route path="/account-detail" element={<AdminAccountDetail />} />
        <Route path="/job-listing" element={<AdminJobListing />} />
        <Route path="/job-detail" element={<AdminJobDetail />} />
        <Route path="/job-log-listing" element={<AdminJobLogListing />} />
      </Routes>
    </AdminLayout>
  )
}
