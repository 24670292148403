/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { Method } from 'axios'
import { ResponseType } from 'axios'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const PRINT_FUNCTION_BASE_URL = process.env.REACT_APP_PRINT_FUNCTION_BASE_URL
const EMAIL_FUNCTION_BASE_URL = process.env.REACT_APP_EMAIL_FUNCTION_BASE_URL

export async function apiRequest(
  method: Method,
  url: string,
  token: string,
  data?: unknown,
  type?: string
): Promise<any> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const request = axios({
      url: API_BASE_URL + url,
      method,
      responseType: <ResponseType | undefined>type,
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data,
    })

    request
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function printJobFunctionRequest(
  method: Method,
  url: string,
  token: string,
  data?: unknown
): Promise<any> {
  return new Promise((resolve, reject) => {
    const request = axios({
      url: PRINT_FUNCTION_BASE_URL + url,
      method,
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data,
    })

    request.then((response) => resolve(response.data)).catch(reject)
  })
}

export async function sendEmailRequest(method: Method, url: string, token: string, data?: unknown): Promise<any> {
  return new Promise((resolve, reject) => {
    const request = axios({
      url: EMAIL_FUNCTION_BASE_URL + url,
      method,
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data,
    })

    request.then((response) => resolve(response.data)).catch(reject)
  })
}

export async function apiFormDataRequest(method: Method, url: string, token: string, data?: unknown): Promise<any> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const request = axios({
      url: API_BASE_URL + url,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + token },
      method: method,
      data,
    })

    request.then((response) => resolve(response.data)).catch(reject)
  })
}
