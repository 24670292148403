import { ReactElement, useState } from 'react'
import { Drawer, Menu } from 'antd'
import { BarsOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useLocation } from 'react-router-dom'
import { MenuItems } from '../Menu/MenuItems'
import { AppHeaderProfile } from './AppHeaderProfile'
import { useProfileContext } from '../../contexts/profileContext'
import { filterMenuItems, transformMenuItem } from '../../utils/extendedMenu'

export function AppHeaderMobileMenu(): ReactElement {
  // TODO: upgrade the styling
  const location = useLocation()
  const { pathname } = location
  const { profile } = useProfileContext()
  const [visible, setVisible] = useState(false)
  const transformedMenuItems = filterMenuItems(MenuItems, profile).map(transformMenuItem)

  const onClose = () => {
    setVisible(false)
  }

  const showDrawer = () => {
    setVisible(true)
  }

  return (
    <>
      <Button
        id="showDrawer"
        type="primary"
        style={{ backgroundColor: '#1890ff', position: 'relative', top: '10px', right: '10px' }}
        onClick={showDrawer}
      >
        <BarsOutlined style={{ fontSize: '26px', color: '#fff' }} />
      </Button>
      <Drawer placement="right" closable={false} onClose={onClose} open={visible}>
        <Menu mode="vertical" selectedKeys={[pathname]} defaultSelectedKeys={[pathname]} items={transformedMenuItems} />
        <div className="mt-2 ml-[-5px]">
          <AppHeaderProfile context="mobile" />
        </div>
      </Drawer>
    </>
  )
}
