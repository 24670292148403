import { ReactElement } from 'react'
import { Button as AntButton } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'

export enum SiteButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

interface Props {
  label: string
  variant?: SiteButtonVariant
  disabled?: boolean
  type?: 'button' | 'submit'
  onClick?: () => void
  style?: React.CSSProperties
  size?: SizeType
  id?: string
  icon?: ReactElement
}

export function AdminButton({
  label,
  variant = SiteButtonVariant.Primary,
  type = 'button',
  disabled = false,
  onClick,
  style,
  size,
  id = '',
  icon,
}: Props): ReactElement {
  const buttonClasses = `${
    variant === SiteButtonVariant.Primary
      ? 'disabled:bg-slate-100 disabled:!text-slate-00 text-white hover:!text-white focus:!text-white bg-credsPrimaryBlue-100 hover:!bg-PrimaryDarkBlue-100 focus:!bg-PrimaryDarkBlue-100  inline-flex items-center'
      : 'rounded-md border border-credsPrimaryBlue-100 bg-PrimaryBgWhite-100 shadow-sm hover:!bg-PrimaryDarkBlue-100 focus:!bg-PrimaryDarkBlue-100 hover:!text-white focus:!text-white  inline-flex items-center'
  }`
  return (
    <AntButton
      id={id}
      size={size}
      style={style}
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
      htmlType={type}
      icon={icon}
    >
      {label}
    </AntButton>
  )
}
