import { ReactElement } from 'react'
import { AppHeaderLogo } from './AppHeaderLogo'
import { AppHeaderDesktopMenu } from './AppHeaderDesktopMenu'
import { AppHeaderMobileMenu } from './AppHeaderMobileMenu'
import { AppHeaderProfile } from './AppHeaderProfile'

export function AppHeader(): ReactElement {
  return (
    <div className="container-fluid site_header_wrapper">
      <div className="header">
        <AppHeaderLogo />
        <div className="mobileHidden flex justify-center items-center mt-[10px] px-10">
          <AppHeaderDesktopMenu />
          <AppHeaderProfile context="desktop" />
        </div>
        <div className="mobileVisible">
          <AppHeaderMobileMenu />
        </div>
      </div>
    </div>
  )
}
