import { ReactElement } from 'react'
import { EventLayout } from '../../components/layout/EventLayout'
import { EventHeader } from '../../components/layout/EventHeader'
import { MetricCard } from '../../components/shared/MetricCard'

export default function EventDashboard(): ReactElement {
  return (
    <EventLayout>
      <div className="container mx-auto">
        <EventHeader pageName="Dashboard" />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
          <MetricCard title="Total Registrants" value="648" change={5.2} date="Today" />
          <MetricCard title="Total Badges Printed" value="496" change={-3.1} date="Today" />
          <MetricCard title="Total Check-Ins" value="454" change={8.0} date="This Week" />
        </div>
      </div>
    </EventLayout>
  )
}
