import { Drawer, message } from 'antd'
import { Fragment, ReactElement, useState } from 'react'
import { SiteButton, SiteButtonVariant } from '../controls/SiteButton'
import { FieldSet } from '../../models/manager/fieldset'
import { DynamicFieldsAdminPrint } from '../dynamicFields/DynamicFieldsAdminPrint'
import { RecordDataRequest } from '../../models/manager/record'
import { useProfileContext } from '../../contexts/profileContext'
import { LoadingSpinner } from '../shared/LoadingSpinner'
import { saveRecordData, updateRecordData } from '../../services/records'
import { useAuth0 } from '@auth0/auth0-react'
import { RecordDataDto } from '../../models/Dtos/RecordDataDto'
import { BlobRenameDto } from '../../models/Dtos/BlobRenameDto'
import { FieldType } from '../../constants/common'
import { getFileExtension } from '../../utils/helper'
import { renameFile } from '../../services/blobStorage'

interface Props {
  fieldSetData: FieldSet[]
  masterListName: string
  openDrawer: boolean
  setOpenDrawer: (isOpen: boolean) => void
  listId: number
  refresh: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedRowData?: Record<string, any> | null
}
export function RecordForm({
  fieldSetData,
  masterListName,
  openDrawer,
  setOpenDrawer,
  listId,
  refresh,
  selectedRowData,
}: Props): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfileContext()
  const [isSaved, setIsSaved] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formData, setFormData] = useState<Record<string, any>>({})
  const [messageApi, contextHolder] = message.useMessage()
  const [isSaving, setIsSaving] = useState(false)

  const updateFieldData = (fieldName: string, fieldValue: string) => {
    setIsSaved(false)
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: fieldValue,
    }))
  }

  const validateForm = (validationErrors: string[] = []) => {
    let isValid = true

    for (const field of fieldSetData) {
      const fieldName = field.Name
      const fieldValue = formData[fieldName]

      if (field.IsRequired && !fieldValue) {
        validationErrors.push(`${fieldName}`)
        isValid = false
      }
    }

    if (!isValid) {
      const errorMessage = validationErrors.map((error, index) => (
        <Fragment key={index}>
          <span>-{error}</span>
          <br />
        </Fragment>
      ))
      messageApi.open({
        type: 'error',
        content: (
          <>
            <span>Please complete required fields:</span>
            <div className="text-left">{errorMessage}</div>
          </>
        ),
        style: { marginTop: '15vh' },
      })
      return false
    }
    return true
  }

  const saveAndNewRecord = async () => {
    const validForm = validateForm([])
    if (validForm) {
      if (selectedRowData) {
        await updateData()
        setOpenDrawer(false)
        return refresh()
      }
      await saveData()
      setOpenDrawer(false)
      return refresh()
    }
  }

  function renameImage(records: RecordDataDto) {
    const imageRecords = records.RecordData.filter((r) =>
      fieldSetData.find((f) => f.Id == r.FieldSetId && f.TypeId == FieldType.Image)
    )
    imageRecords.map(async (imageRecord) => {
      const renameInfo: BlobRenameDto = {
        OldFileName: `${profile.AccountUid}/record-data/${listId}/${imageRecord.Value}`,
        NewFileName: `${profile.AccountUid}/record-data/${listId}/${
          imageRecord.FieldSetId
        }-${imageRecord.RecordId.toString()}.${getFileExtension(imageRecord.Value) || ''}`,
      }
      await renameFile(renameInfo, await getAccessTokenSilently())
    })
  }

  const convertFormData = () => {
    const newArray = Object.entries(formData).map(([property, value]) => ({
      property,
      value,
    }))
    const dataToSave: RecordDataRequest[] = newArray.map((item) => {
      return {
        FieldSetId: fieldSetData.find((f) => f.Name == item.property)?.Id || 0,
        Value: item.value,
        RecordId: selectedRowData?.__cn_id || 0,
        CreatedBy: profile.Email,
        UpdatedBy: profile.Email,
      }
    })
    return dataToSave
  }

  const saveData = async () => {
    try {
      setIsSaving(true)
      const recordDataToSave = convertFormData()
      const result = await saveRecordData(
        {
          RecordData: recordDataToSave,
          RecordSetId: listId,
          CreatedBy: profile.Email,
          UpdatedBy: profile.Email,
        },
        await getAccessTokenSilently()
      )

      // based on the result of saving, we now have a recordId, so if there are any images
      // in the set of fields, we need to rename the blob storage item to the fieldId + recordId
      renameImage(result)

      messageApi.open({
        type: 'success',
        content: 'Record saved successfully',
        style: { marginTop: '15vh' },
      })
      setFormData({})
      clearFields()
      setIsSaving(false)
      return true
    } catch (error) {
      setIsSaving(false)
      messageApi.open({
        type: 'error',
        content: 'Could not save the Record, please try again',
        style: { marginTop: '15vh' },
      })
    }
  }

  const updateData = async () => {
    try {
      setIsSaving(true)
      const recordDataToSave = convertFormData()
      await updateRecordData(
        {
          RecordData: recordDataToSave,
          RecordSetId: selectedRowData?.Id,
          CreatedBy: profile.Email,
          UpdatedBy: profile.Email,
        },
        await getAccessTokenSilently()
      )

      messageApi.open({
        type: 'success',
        content: 'Record saved successfully',
        style: { marginTop: '15vh' },
      })
      setFormData({})
      clearFields()
      setIsSaving(false)
      return true
    } catch (error) {
      setIsSaving(false)
      messageApi.open({
        type: 'error',
        content: 'Could not save the Record, please try again',
        style: { marginTop: '15vh' },
      })
    }
  }

  const clearFields = () => {
    setIsSaved(true)
  }

  const getValue = (fieldName: string): string => {
    if (selectedRowData) {
      if (typeof selectedRowData[fieldName] === 'object') return ''
      return selectedRowData[fieldName] ?? ''
    }
    return ''
  }

  return (
    <>
      {isSaving && <LoadingSpinner isLoading={isSaving} label="Saving..." />}
      {contextHolder}
      <Drawer
        rootClassName="custom-drawer-width"
        width={'400 !important'}
        closable
        destroyOnClose
        title={
          <div className="flex justify-between items-center">
            <span className="font-semibold">{selectedRowData ? 'Edit Record' : 'Add Record'}</span>
            <div className="flex items-center gap-2">
              <SiteButton
                variant={SiteButtonVariant.Secondary}
                onClick={() => setOpenDrawer(false)}
                label={'Cancel'}
                id={'cancel'}
                style={{ margin: 0 }}
              />
              <SiteButton label={'Save'} id={'save'} onClick={saveAndNewRecord} style={{ margin: 0 }} />
            </div>
          </div>
        }
        placement="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <div className="flex flex-col gap-2">
          <span className="text-base text-TextTertiaryGrey-100 font-semibold">List: {masterListName}</span>
          {fieldSetData &&
            fieldSetData.map((field) => (
              <DynamicFieldsAdminPrint
                onUpdateFieldData={updateFieldData}
                key={field.Id}
                fieldSetData={field}
                recordId={0}
                isSaved={isSaved}
                value={() => getValue(field.Name)}
              />
            ))}
        </div>
      </Drawer>
    </>
  )
}
