import { Input, Modal, Select, message } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { createContact, fetchUser, sendAccountWelcomeEmail, updateAccount, updateUser } from '../../services/accounts'
import { User } from '../../models/manager/user'
import { errorHandler } from '../../utils/helper'
import { SiteButton, SiteButtonVariant } from '../controls/SiteButton'
import { Account, Welcome } from '../../models/manager/account'
import { useAuth0 } from '@auth0/auth0-react'
import { AddressRequest, Country, Region } from '../../models/manager/address'
import { fetchCountries, fetchRegionByCountryId, saveAddress } from '../../services/addresses'
import { useProfileContext } from '../../contexts/profileContext'
import { SG_CONTACT_LIST_ID } from '../../constants/common'

export interface Props {
  isOpen: boolean
}

const initialUserState: User = {
  Id: 0,
  FirstName: '',
  LastName: '',
  Company: '',
  Phone: '',
  JobTitle: '',
  Email: '',
  AuthUid: '',
  UpdatedAt: new Date(),
  UpdatedBy: '',
  CreatedAt: new Date(),
  CreatedBy: '',
  AccountUsers: [],
}

const initialAccountState: Account = {
  Id: 0,
  AccountGuid: '',
  Name: '',
  IsActive: true,
  PlanId: 0,
  UpdatedAt: new Date(),
  UpdatedBy: '',
  CreatedAt: new Date(),
  CreatedBy: '',
  Plan: {
    Id: 0,
    Name: '',
    Description: '',
    Price: 0,
    CostPerUnit: 0,
    CostPerCr80: 0,
  },
}

export function SignupInfo({ isOpen }: Props): ReactElement {
  const [messageApi, contextHolder] = message.useMessage()
  const { getAccessTokenSilently } = useAuth0()
  const { profile, saveProfile } = useProfileContext()

  const [userData, setUserData] = useState<User>(initialUserState)
  const [accountData, setAccountData] = useState<Account>(initialAccountState)
  const [countryList, setCountryList] = useState<Country[]>([])
  const [regionList, setRegionList] = useState<Region[]>([])
  const [countrySelected, setCountrySelected] = useState<number | undefined>()
  const [regionSelected, setRegionSelected] = useState<number | undefined>()

  const goTermsConditions = () => {
    window.open('https://www.credsnow.com/termsandconditions', '_blank')
  }

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      fetchCountries(token).then((countryData) => {
        setCountryList(countryData)
        // default to USA, TODO: remove this hardcode after MVP
        setCountrySelected(2)
      })
      fetchUser(profile.AuthUid, token)
        .then((data) => {
          setUserData(data)
          if (data.AccountUsers && data.AccountUsers.length > 0 && data.AccountUsers[0].Account) {
            setAccountData(data.AccountUsers[0].Account)
          }
        })
        .catch(errorHandler)
    })
  }, [])

  useEffect(() => {
    if (countrySelected && countrySelected > 0) {
      getAccessTokenSilently().then((token) => {
        fetchRegionByCountryId(countrySelected, token).then((regionData) => {
          setRegionList(regionData)
        })
      })
    }
  }, [countrySelected])

  function validateRequiredFields(): boolean {
    return (
      (userData.FirstName?.trim() ?? '').length > 1 &&
      (userData.LastName?.trim() ?? '').length > 1 &&
      accountData.Name.trim().length > 1
    )
  }

  function handleUserChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = e.target
    setUserData({
      ...userData,
      [name]: value,
    })
  }

  function handleAccountNameChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { value } = e.target
    setAccountData({
      ...accountData,
      Name: value,
    })
  }

  async function handleSave() {
    if (!validateRequiredFields()) {
      messageApi.open({
        type: 'error',
        content: <span>Please complete required fields</span>,
      })
      return
    }

    // save an address entry for the account and mark isAccountAddress = true
    const accountAddress: AddressRequest = {
      AccountId: accountData.Id,
      Address1: '',
      City: '',
      RegionId: regionSelected,
      IsDefault: false,
      IsAccountAddress: true,
      UpdatedBy: profile.Email,
    }
    saveAddress(accountAddress, await getAccessTokenSilently()).then(async (addressId) => {
      updateUser(userData, await getAccessTokenSilently()).then(async () => {
        accountData.AddressId = addressId
        updateAccount(accountData, await getAccessTokenSilently())
          .then(async () => {
            saveProfile({ ...profile, IsProfileComplete: true, AccountName: accountData.Name })
          })
          .then(async () => {
            const emailRequest: Welcome = {
              ToEmail: userData.Email,
              UserFirstName: userData.FirstName ?? '',
              UserAccountName: accountData.Name ?? '',
              Environment: process.env.REACT_APP_AUTH0_RETURN_URL ?? '',
            }
            await sendAccountWelcomeEmail(emailRequest, await getAccessTokenSilently())
            await createContact(
              {
                Email: userData.Email,
                FirstName: userData.FirstName || '',
                LastName: userData.LastName || '',
                AccountName: accountData.Name,
                ListId: SG_CONTACT_LIST_ID,
              },
              await getAccessTokenSilently()
            )
          })
          .then(() => {
            window.location.reload()
          })
          .catch(errorHandler)
      })
    })
  }

  return (
    <Modal footer={null} open={isOpen} closeIcon={null} mask={false} closable={false}>
      {contextHolder}
      <div className="flex flex-col gap-2 items-center mx-auto py-5">
        <h1 className="text-PrimaryDarkBlue-100 font-bold text-2xl md:text-3xl">Welcome to Credsnow</h1>
        <span className="text-credsPrimaryBlue-100 text-base text-center md:w-2/3">
          We just need a few more key details from you to complete your account setup
        </span>
      </div>
      <div className="flex flex-col w-full md:px-10 mt-6 gap-5">
        <div className="flex flex-col">
          <label className="text-PrimaryDarkBlue-100 font-semibold">
            <span className="text-ErrorPrimary-100 text-sm">*&nbsp;</span>Select your default country
          </label>
          <Select
            disabled={true}
            className="w-full"
            onChange={(e) => setCountrySelected(e)}
            value={countrySelected}
            options={[
              ...countryList.map((country) => ({
                value: country.Id,
                label: country.Name,
              })),
            ]}
          />
          <span className="text-PrimaryDarkBlue-100 text-xs">The country where your orders are being shipped.</span>
        </div>
        <div className="flex flex-col">
          <label className="text-PrimaryDarkBlue-100 font-semibold">
            <span className="text-ErrorPrimary-100 text-sm">*&nbsp;</span>Select your region, state, province, etc.
          </label>
          <Select
            onChange={(e) => setRegionSelected(e)}
            value={regionSelected}
            placeholder="Select an option"
            className="w-auto sm:w-[80%]"
          >
            {regionList.map((region) => (
              <Select.Option key={region.Id} value={region.Id}>
                {region.Name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row gap-2">
            <div>
              <label className="text-PrimaryDarkBlue-100 font-semibold">
                <span className="text-ErrorPrimary-100 text-sm">*&nbsp;</span>First Name
              </label>
              <Input
                className="w-full"
                id="firstName"
                name="FirstName"
                placeholder="Enter your first name"
                value={userData?.FirstName}
                onChange={handleUserChange}
              />
            </div>
            <div>
              <label className="text-PrimaryDarkBlue-100 font-semibold">
                <span className="text-ErrorPrimary-100 text-sm">*&nbsp;</span>Last Name
              </label>
              <Input
                className="w-full"
                id="lastName"
                name="LastName"
                placeholder="Enter your last name"
                value={userData?.LastName}
                onChange={handleUserChange}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <label className="text-PrimaryDarkBlue-100 font-semibold">
            <span className="text-ErrorPrimary-100 text-sm">*&nbsp;</span>Account Name
          </label>
          <Input
            className="w-full"
            id="accountName"
            name="AccountName"
            placeholder="Create a name for you new account"
            value={accountData?.Name}
            onChange={handleAccountNameChange}
          />
          <span className="text-PrimaryDarkBlue-100 text-xs">
            Enter company or organization name that will be using Credsnow
          </span>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="flex justify-center items-center gap-2">
            <SiteButton
              style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
              size="large"
              variant={SiteButtonVariant.Secondary}
              onClick={handleSave}
              label={'Complete Account Setup >'}
              id="Complete"
            />
          </div>
          <span className="text-sm text-center">
            Creating your account means you accept the Credsnow{' '}
            <span className="text-credsPrimaryBlue-100 text-sm cursor-pointer" onClick={goTermsConditions}>
              Terms and Conditions
            </span>
          </span>
        </div>
      </div>
    </Modal>
  )
}
