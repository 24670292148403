import { Template, TemplateRequest } from '../models/manager/template'
import { apiRequest, sendEmailRequest } from '../utils/axios'
import queryString from 'query-string'

interface UserDesignSubmission {
  UserEmail: string
  AccountName: string
  AccountUId: string
  BadgeSize: string
  BadgeSides: string
  BadgeContact: string
  BadgeDescription: string
  StorageFolder: string
  SubmissionDate: string
}

export async function fetchTemplates(accountId: number, token: string): Promise<Template[]> {
  return apiRequest('GET', `/templates/${accountId}`, token)
}

export async function fetchTemplate(templateId: number, token: string): Promise<Template> {
  return apiRequest('GET', `/template/${templateId}`, token)
}

export async function fetchTemplatesByRecordSetId(recordSetId: number, token: string): Promise<Template[]> {
  return apiRequest('GET', `/templatesForRecordSet/${recordSetId}`, token)
}

export async function saveTemplate(templateData: TemplateRequest, token: string): Promise<number> {
  return apiRequest('POST', '/template', token, templateData)
}

export async function updateTemplate(templateData: TemplateRequest, token: string): Promise<void> {
  return apiRequest('PUT', '/template', token, templateData)
}

export async function deleteTemplate(templateId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/template/${templateId}`, token)
}

export async function countTemplates(accountId: number, token: string): Promise<number> {
  return apiRequest('GET', `/templateCount/${accountId}`, token)
}

export async function getTemplateTypeById(templateId: number, token: string): Promise<number> {
  return apiRequest('GET', `/templateType/${templateId}`, token)
}

export async function templatesUsingList(
  recordSetId: number,
  accountId: number,
  token: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  return apiRequest('GET', `/templatesUsingList/${recordSetId}/${accountId}`, token)
}

export async function templatesUsingImage(
  accountId: string,
  imageId: string,
  token: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  return apiRequest('GET', `/templatesUsingImage/${accountId}/${imageId}`, token)
}

export async function sendUserDesignSubmission(
  userDesignSubmission: UserDesignSubmission,
  token: string
): Promise<void> {
  return sendEmailRequest(
    'GET',
    `/createDesignUploadNotificationInternal?${queryString.stringify(userDesignSubmission)}`,
    token
  )
}
