import { ReactElement, useState } from 'react'
import { ContextProps, createContext } from '../utils/contextHelper'
import { ProfileData } from '../models/profileData'
import { Roles } from '../constants/common'
import { decryptData, encryptData } from '../utils/permissions'

interface ProfileContextState {
  profile: ProfileData
  saveProfile: (newProfile: ProfileData) => void
  clearProfile: () => void
}

const [Provider, useProfileContext] = createContext<ProfileContextState>(module.filename)

export { useProfileContext }

export function ProfileProvider({ children }: ContextProps): ReactElement {
  const [profile, setProfile] = useState<ProfileData>(() => {
    const storedValue = localStorage.getItem('p')
    return storedValue
      ? JSON.parse(decryptData(storedValue))
      : {
          FirstName: '',
          LastName: '',
          AccountId: 0,
          Email: '',
          AccountUid: '',
          Permissions: 0,
          AuthUid: '',
          AccountName: '',
          IsProfileComplete: false,
          IsPlanComplete: false,
          Role: Roles.User,
          AuthRoles: [],
          IsImpersonating: false,
        }
  })

  function saveProfile(newProfile: ProfileData) {
    setProfile({ ...profile, ...newProfile })
    localStorage.setItem('p', encryptData(JSON.stringify(newProfile)))
  }

  return (
    <Provider
      value={{
        profile,
        saveProfile,
        clearProfile: () =>
          saveProfile({
            FirstName: '',
            LastName: '',
            AccountId: 0,
            Email: '',
            AccountUid: '',
            Permissions: 0,
            AuthUid: '',
            AccountName: '',
            IsProfileComplete: false,
            IsPlanComplete: false,
            Role: Roles.User,
            AuthRoles: [],
            IsImpersonating: false,
          }),
      }}
    >
      {children}
    </Provider>
  )
}
