import { Skeleton, Table } from 'antd'
import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../../components/shared/LoadingSpinner'
import { errorHandler } from '../../../utils/helper'
import { useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { fetchTemplates } from '../../../services/templates'
import { Template, TemplateType } from '../../../models/manager/template'
import { useAuth0 } from '@auth0/auth0-react'

interface Props {
  accountId: number
}

export default function AdminAccountTemplates({ accountId }: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [filteredDataSource, setFilteredDataSource] = useState<Template[]>([])

  const columns: ColumnsType<Template> = [
    {
      key: 'id',
      title: 'Id',
      dataIndex: 'Id',
      width: 60,
      sorter: (a, b) => a.Id - b.Id,
      render: (id: number) => {
        return <div className="pr-2 pl-2">{id}</div>
      },
    },
    {
      key: 'locked',
      title: 'Locked',
      dataIndex: 'IsLocked',
      width: 60,
      sorter: (a) => (a.IsLocked ? -1 : 1),
      render: (IsLocked: boolean) => {
        return (
          <div className="flex flex-[1] flex-nowrap whitespace-nowrap not-italic font-normal pr-2 pl-2">
            {IsLocked ? 'Yes' : 'No'}
          </div>
        )
      },
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'Name',
      width: 150,
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (templateName) => {
        return (
          <div className="flex flex-nowrap justify-start items-center gap-1">
            <div className="flex-[1] px-[8px] py-px gap-[4px] cursor-pointer flex-nowrap">
              <div className="text-[#000000] not-italic font-normal pr-2 pl-2 overflow-hidden overflow-ellipsis w-[fit-content] whitespace-nowrap max-w-[25ch]">
                {templateName}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      key: 'list',
      title: 'List',
      dataIndex: 'ListName',
      width: 100,
      sorter: (a, b) => a.Id - b.Id,
      render: (list) => {
        return (
          <div className="flex flex-nowrap justify-start items-center gap-1">
            <div className="flex-[1] px-[8px] py-px gap-[4px] cursor-pointer flex-nowrap">
              <div className="text-[#000000] not-italic font-normal pr-2 pl-2 overflow-hidden overflow-ellipsis w-[fit-content] whitespace-nowrap max-w-[20ch]">
                {list}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      key: 'type',
      title: 'Type',
      dataIndex: 'TemplateType',
      width: 100,
      sorter: (a, b) => a.TemplateType.Id - b.TemplateType.Id,
      render: (templateType: TemplateType) => {
        return <div className="pr-2 pl-2 whitespace-nowrap">{templateType.Name}</div>
      },
    },
    {
      key: 'Edited',
      title: 'Edited',
      dataIndex: 'UpdatedAt',
      render: (UpdatedAt: number) => {
        return <div className="pr-2 pl-2 pt-1 pb-1 whitespace-nowrap">{dayjs.utc(UpdatedAt).local().fromNow()}</div>
      },
      sorter: (a, b) => (dayjs(a.UpdatedAt).isBefore(b.UpdatedAt) ? -1 : 1),
      width: 90,
    },
  ]

  const onRowSelected = (template: Template, event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    event?.stopPropagation()
    navigate('/create-template', { state: { templateId: template.Id } })
  }

  useEffect(() => {
    getAccessTokenSilently().then(async (token) => {
      fetchTemplates(accountId, token)
        .then((data) => {
          data.sort((a, b) => (dayjs(a.UpdatedAt).isBefore(b.UpdatedAt) ? 1 : -1))
          setFilteredDataSource(data.filter((template) => !template.IsAvailableToAll))
        })
        .catch(errorHandler)
        .finally(() => setIsLoading(false))
    })
  }, [])

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      {isLoading && <LoadingSpinner isLoading={isLoading} label="Loading..." />}
      <div className="flex-col flex-wrap">
        <div className="adminContainerRow">
          <div className="adminContainer">
            <div id="adminAccountTemplates" className="mt-1">
              {isLoading ? (
                <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
              ) : (
                <Table<Template>
                  id="view-templates"
                  pagination={{
                    pageSizeOptions: ['10', '20', '30', '50'],
                    showSizeChanger: true,
                  }}
                  bordered
                  className="adminTableRow"
                  rowKey={(record) => record.Id}
                  columns={columns}
                  size="small"
                  dataSource={filteredDataSource}
                  onRow={(record) => {
                    return {
                      onClick: (e) => {
                        onRowSelected(record, e)
                      },
                    }
                  }}
                  onHeaderRow={() => ({
                    className:
                      'text-[12px] !text-[#7E7E7E] not-italic !font-normal leading-[20px] tracking-wide text-left !bg-[white]',
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}
