import { PrintQueueDto } from '../models/Dtos/PrintQueueDto'
import {
  AdminPrintLookupForm,
  AdminPrintLookupFormRequest,
  PrintAgent,
  QuickPrintSearchRequest,
  QuickPrintSearchResponse,
} from '../models/manager/printAgent'
import { apiRequest } from '../utils/axios'

export async function fetchPrintAgents(accountId: number, token: string): Promise<PrintAgent[]> {
  return apiRequest('GET', `/printAgent/agents/${accountId}`, token)
}

export async function addRecordToPrintQueue(printQueueItem: PrintQueueDto, token: string): Promise<void> {
  return apiRequest('POST', `/printAgent/queue/`, token, printQueueItem)
}

export async function updateSelectedPrinter(printAgentId: number, printerId: number, token: string): Promise<void> {
  return apiRequest('PUT', `/printAgent/selectedPrinter/${printAgentId}/${printerId}`, token)
}

export async function updatePrintAgentName(data: { Id: number; Name: string }, token: string): Promise<void> {
  return apiRequest('PATCH', `/printAgent/agentName`, token, data)
}

export async function quickPrintSearch(
  request: QuickPrintSearchRequest,
  token: string
): Promise<QuickPrintSearchResponse[]> {
  return apiRequest('POST', `/printAgent/quickPrint`, token, request)
}

export async function fetchAdminLookupForms(accountId: number, token: string): Promise<AdminPrintLookupForm[]> {
  return apiRequest('GET', `/printAgent/lookupForms/${accountId}`, token)
}

export async function fetchAdminLookupForm(lookupFormId: number, token: string): Promise<AdminPrintLookupForm> {
  return apiRequest('GET', `/printAgent/lookupForm/${lookupFormId}`, token)
}

export async function saveAdminLookupForm(
  payload: AdminPrintLookupFormRequest,
  token: string
): Promise<AdminPrintLookupForm> {
  return apiRequest('POST', `/printAgent/lookupForm`, token, payload)
}

export async function updateAdminLookupForm(
  payload: AdminPrintLookupFormRequest,
  token: string
): Promise<AdminPrintLookupForm> {
  return apiRequest('PUT', `/printAgent/lookupForm`, token, payload)
}

export async function switchIsHidden(id: number, isHidden: boolean, token: string): Promise<AdminPrintLookupForm> {
  return apiRequest('PATCH', `/printAgent/lookupForm/${id}/hidden/${isHidden}`, token)
}
