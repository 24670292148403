import { ReactElement } from 'react'

interface Props {
  title: string
  value: string
  change: number
  date: string
}

export function MetricCard({ title, value, change, date }: Props): ReactElement {
  return (
    <div className="border rounded-lg shadow-md p-4 w-full bg-white">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-3xl font-bold mb-1">{value}</p>
      <p className={`text-sm ${change >= 0 ? 'text-green-500' : 'text-red-500'}`}>
        {change >= 0 ? '+' : ''}
        {change}%
      </p>
      <p className="text-xs text-gray-500">{date}</p>
    </div>
  )
}
