import { ReactElement, useEffect, useState } from 'react'
import { errorHandler } from '../../utils/helper'
import { fetchFileAsDataUrl } from '../../services/blobStorage'
import { Skeleton } from 'antd'
import { TEMPLATE_THUMBNAIL_FOLDER } from '../../constants/common'
import { useAuth0 } from '@auth0/auth0-react'
import { TemplateType } from '../../models/manager/template'
interface Props {
  templateId: number
  extension: string
  thumbnailLocation: string
  type?: TemplateType
  width?: string
  mode?: string
}

export const TemplateThumbnail = ({
  templateId,
  extension,
  thumbnailLocation,
  type,
  width,
  mode,
}: Props): ReactElement => {
  const { getAccessTokenSilently } = useAuth0()
  const [imgUrl, setImgUrl] = useState('')

  useEffect(() => {
    if (templateId > 0) {
      const source = thumbnailLocation
      const fileUrl = `${source}${TEMPLATE_THUMBNAIL_FOLDER}/${templateId}_tn.${extension}`

      getAccessTokenSilently().then((token) => {
        fetchFileAsDataUrl(fileUrl, token)
          .then((data) => {
            setImgUrl(data.data)
          })
          .catch(errorHandler)
      })
    }
  }, [templateId])

  const listSpanWidth = width ? `min-w-[${width}px] max-w-[${width}px]` : 'min-w-[50px] max-w-[50px]'
  const listImgWidth = width
    ? `mt-[-25px] mb-[-25px] min-h-[${width}px] max-h-[${width}px] min-w-[${width}px] max-w-[${width}px] border-[1px] border-[solid] border-[silver] rounded-[4px] object-fill`
    : 'min-h-[50px] max-h-[50px] min-w-[50px] max-w-[50px] border-[2px] border-[solid] border-[silver] rounded-[8px] object-fill'

  return (
    <>
      {mode == 'list' ? (
        <span className={listSpanWidth}>
          {imgUrl ? (
            <img alt="template thumbnail" className={listImgWidth} src={imgUrl} />
          ) : (
            <Skeleton.Avatar size={50} />
          )}
        </span>
      ) : (
        <div style={{ display: 'block', minWidth: '350px !important', maxWidth: '350px !important' }}>
          <div className="templateThumbnailBox">
            <div className={mode === 'grid' ? 'templateThumbnailContainerGrid' : 'templateThumbnailContainerRow'}>
              <div className={mode === 'grid' ? 'templateThumbnailWrapperGrid' : 'templateThumbnailWrapperRow'}>
                <div
                  className={
                    // eslint-disable-next-line no-nested-ternary
                    mode === 'grid'
                      ? (type?.Width ?? 0) > (type?.Height ?? 0)
                        ? 'horizontalCardPreviewGrid'
                        : 'verticalCardPreviewGrid'
                      : (type?.Width ?? 0) < (type?.Height ?? 0)
                      ? 'horizontalCardPreviewRow'
                      : 'verticalCardPreviewRow'
                  }
                >
                  <div className="block100w100hAbsolute">
                    <div className="block100w100hRelative">
                      <div className="templateThumbnailPreviewWrapper">
                        {imgUrl ? (
                          <>
                            <img
                              src={imgUrl}
                              loading="lazy"
                              className="templateThumbnail"
                              width={width}
                              alt={templateId.toString()}
                            />
                          </>
                        ) : (
                          <Skeleton.Avatar size={50} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
