import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../components/shared/LoadingSpinner'
import { AdminButton } from '../components/controls/AdminButton'
import { Input, Skeleton, Table, Tag } from 'antd'
import { Job } from '../../models/manager/job'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { errorHandler, insertSpaceCamelCase, statusColor } from '../../utils/helper'
import { LocalStorageItems, Status } from '../../constants/common'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { fetchJobs } from '../services/adminJob'

const AdminJobListing = (): ReactElement => {
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [searchParams] = useSearchParams()
  const statusId = searchParams.get('statusId')
  const [isLoading, setIsLoading] = useState(true)
  const [jobsData, setJobsData] = useState<Job[]>([])
  const [filteredDataSource, setFilteredDataSource] = useState<Job[]>([])
  const [searchText, setSearchText] = useState('')
  const IsShowingCancelled = JSON.parse(localStorage.getItem(LocalStorageItems.ShowCancelledStatus) || 'true')

  const onRowSelected = (job: Job, event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    event?.stopPropagation()
    navigate(`/admin/job-detail?jobId=${job.Id}`, { state: { accountName: job?.Account?.Name } })
  }

  const statusFilters = Object.entries(Status)
    .filter(([key]) => !isNaN(Number(key)))
    .filter(([key]) => {
      if (!IsShowingCancelled) {
        return Number(key) !== Status.Cancelled
      }
      return true
    })
    .map(([key, value]) => {
      return {
        text: insertSpaceCamelCase(value.toString()),
        value: Number(key),
      }
    })

  const columns: ColumnsType<Job> = [
    {
      key: 'updated',
      title: 'Updated',
      dataIndex: 'UpdatedAt',
      render: (date) => dayjs(date).format('YYYY-MM-DD'),
      sorter: (a, b) => (dayjs(a.UpdatedAt).isBefore(b.UpdatedAt) ? -1 : 1),
      width: 200,
    },
    {
      key: 'id',
      title: 'Job Id',
      dataIndex: 'Id',
      width: 100,
    },
    {
      key: 'name',
      title: 'Job Name',
      dataIndex: 'Name',
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      key: 'Account',
      title: 'Account',
      dataIndex: '',
      render: (job) => job.Account.Name,
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      key: 'quantity',
      title: 'Quantity',
      dataIndex: '',
      render: (job) => job.JobLogs?.length,
      width: 100,
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'StatusId',
      width: 100,
      render: (sid) => (
        <Tag style={statusColor(sid)} key={sid}>
          {insertSpaceCamelCase(Status[sid])}
        </Tag>
      ),
      sorter: (a, b) => a.StatusId - b.StatusId,
      filters: statusFilters,
      onFilter: (value, record) => record.StatusId === value,
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: '',
      width: 150,
      render: (job: Job) => {
        return (
          <div className="flex justify-center items-center">
            <span className="cursor-pointer text-credsPrimaryBlue-100" onClick={(event) => onRowSelected(job, event)}>
              View
            </span>
          </div>
        )
      },
    },
  ]

  const onSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClose = () => {
    navigate('/admin/dashboard')
  }

  useEffect(() => {
    getAccessTokenSilently().then(async (token) => {
      fetchJobs(token)
        .then((data) => {
          data.sort((a, b) => (dayjs(a.CreatedAt).isBefore(b.CreatedAt) ? 1 : -1))
          if (statusId) {
            data = data.filter((job) => job.StatusId === parseInt(statusId))
          }
          if (!IsShowingCancelled) {
            data = data.filter((job) => job.StatusId !== Status.Cancelled)
          }
          setJobsData(data)
          setFilteredDataSource(data)
        })
        .catch(errorHandler)
        .finally(() => setIsLoading(false))
    })
  }, [])

  useEffect(() => {
    const lowercasedSearchText = searchText.toLowerCase()

    const filteredData = jobsData
      ?.filter((item) => {
        const idMatch = item.Id.toString().includes(lowercasedSearchText)
        const nameMatch = item?.Name?.toLowerCase().includes(lowercasedSearchText)
        const accountMatch = item?.Account?.Name.toLowerCase().includes(lowercasedSearchText)
        return idMatch || nameMatch || accountMatch
      })
      .sort((a, b) => (dayjs(a.UpdatedAt).isBefore(b.UpdatedAt) ? 1 : -1))
    setFilteredDataSource(filteredData)
  }, [jobsData, searchText])

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      <div className="container mx-auto">
        <div className="flex justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6 my-2 py-2">
          <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Admin Print Jobs</h1>
          <AdminButton label={'Close'} onClick={handleClose} />
        </div>
        <div className="flex-col justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6 my-3 h-full py-2">
          <div className="flex justify-center items-center w-full py-2">
            <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Summary</h1>
            <Input.Search
              value={searchText}
              style={{ width: 'auto' }}
              placeholder="Search..."
              onChange={(e) => onSearch(e.target.value)}
              allowClear
            />
          </div>
          <div className="mt-1">
            {isLoading ? (
              <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
            ) : (
              <Table<Job>
                pagination={{
                  pageSizeOptions: ['10', '20', '30', '50'],
                  showSizeChanger: true,
                }}
                bordered
                rowKey={(record) => record.Id}
                columns={columns}
                size="small"
                dataSource={filteredDataSource}
              />
            )}
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default AdminJobListing
