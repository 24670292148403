import { Skeleton, Table } from 'antd'
import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../../components/shared/LoadingSpinner'
import { errorHandler, insertSpaceCamelCase } from '../../../utils/helper'
import { Transaction } from '../../../models/manager/transaction'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { fetchTransactions } from '../../../services/transactions'
import { useAuth0 } from '@auth0/auth0-react'
import { TransactionType } from '../../../constants/common'

interface Props {
  accountId: number
}

export default function AdminAccountTransactions({ accountId }: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const [filteredDataSource, setFilteredDataSource] = useState<Transaction[]>([])
  const { getAccessTokenSilently } = useAuth0()
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([])

  const columns: ColumnsType<Transaction> = [
    {
      key: 'TransactionDate',
      title: 'Txn Date',
      dataIndex: 'TransactionDate',
      sorter: (a, b) => (dayjs(a.TransactionDate).isBefore(b.TransactionDate) ? -1 : 1),
      render: (TransactionDate: Date) => {
        return (
          <div className="flex flex-[1] flex-nowrap whitespace-nowrap not-italic font-normal pr-2 pl-2">
            {dayjs(TransactionDate).format('YYYY-MM-DD')}
          </div>
        )
      },
      width: 90,
    },
    {
      key: 'JobId',
      title: 'JobId',
      dataIndex: 'JobId',
      width: 60,
      sorter: (a, b) => (a.JobId || 0) - (b.JobId || 0),
      render: (JobId: number) => {
        return <div className="pr-2 pl-2">{JobId}</div>
      },
    },
    {
      key: 'TransactionTypeId',
      title: 'Type',
      dataIndex: 'TransactionTypeId',
      width: 120,
      sorter: (a, b) => (a.TransactionTypeId || 0) - (b.TransactionTypeId || 0),
      render: (TransactionTypeId: number) => {
        return (
          <div className="pr-2 pl-2 whitespace-nowrap">{insertSpaceCamelCase(TransactionType[TransactionTypeId])}</div>
        )
      },
    },
    {
      key: 'Amount',
      title: 'Charges',
      dataIndex: 'Amount',
      width: 60,
      sorter: (a, b) => (a.Amount || 0) - (b.Amount || 0),
      render: (Amount: number) => {
        return (
          <div className="pr-2 pl-2 text-right text-[red] font-normal">
            {Amount < 0 ? (Amount * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : (0).toFixed(2)}
          </div>
        )
      },
    },
    {
      key: 'Amount',
      title: 'Payments',
      dataIndex: 'Amount',
      width: 60,
      sorter: (a, b) => (a.Amount || 0) - (b.Amount || 0),
      render: (Amount: number) => {
        return (
          <div className="pr-2 pl-2 text-right text-[forestgreen] font-normal">
            {Amount >= 0 ? Amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : (0).toFixed(2)}
          </div>
        )
      },
    },

    {
      key: 'TaxAmount',
      title: 'Tax',
      dataIndex: 'TaxAmount',
      width: 60,
      sorter: (a, b) => (a.TaxAmount || 0) - (b.TaxAmount || 0),
      render: (TaxAmount: number) => {
        return <div className="pr-2 pl-2 text-right">{TaxAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</div>
      },
    },
    {
      key: 'CurrencyCode',
      title: 'Currency',
      dataIndex: 'CurrencyCode',
      sorter: (a, b) => a.CurrencyCode.localeCompare(b.CurrencyCode),
      render: (CurrencyCode: string) => {
        return <div className="pr-2 pl-2 text-right">{CurrencyCode}</div>
      },
      width: 60,
    },
    {
      key: 'CreatedBy',
      title: 'User',
      dataIndex: 'CreatedBy',
      sorter: (a, b) => a.CreatedBy.localeCompare(b.CreatedBy),
      render: (CreatedBy: string) => {
        return <div className="pr-2 pl-2 pt-1 pb-1 text-left">{CreatedBy}</div>
      },
      width: 200,
    },
    {
      key: 'StripeTransactionId',
      title: 'Stripe Txn Id',
      dataIndex: 'StripeTransactionId',
      render: (StripeTransactionId: string) => {
        return (
          <div className="pr-2 pl-2 pt-1 pb-1 text-left">
            {StripeTransactionId && StripeTransactionId.length > 0
              ? `${StripeTransactionId.substring(0, 8)}**********`
              : ''}
          </div>
        )
      },
      width: 120,
    },
    {
      key: 'Accumulator',
      title: 'Balance',
      dataIndex: 'Accumulator',
      render: (Accumulator: number) => {
        return (
          <div className="pr-2 pl-2 pt-0 pb-0 text-right font-semibold">
            {(Accumulator || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
          </div>
        )
      },
      width: 90,
    },
  ]

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      fetchTransactions(accountId, token)
        .then((data) => {
          data.sort((a, b) => (dayjs(a.CreatedAt).isBefore(b.CreatedAt) ? 1 : -1))
          setTransactionsData(data)
          setFilteredDataSource(data)
        })
        .catch(errorHandler)
        .finally(() => setIsLoading(false))
    })
  }, [])

  useEffect(() => {
    const filteredData = transactionsData
      .sort((a, b) => (dayjs(a.CreatedAt).isAfter(b.CreatedAt) ? 1 : -1))
      .map((item, index, array) => {
        const accumulator = array.slice(0, index + 1).reduce((acc, curr) => acc + (curr.Amount || 0), 0)
        return {
          ...item,
          Accumulator: accumulator,
        }
      })
    filteredData.reverse()
    setFilteredDataSource(filteredData)
  }, [transactionsData])

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      {isLoading && <LoadingSpinner isLoading={isLoading} label="Loading..." />}
      <div className="flex-col flex-wrap">
        <div className="adminContainerRow">
          <div className="adminContainer">
            <div id="adminAccountTransactions" className="mt-1">
              {isLoading ? (
                <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
              ) : (
                <Table<Transaction>
                  id="view-transactions"
                  pagination={{
                    pageSizeOptions: ['10', '20', '30', '50'],
                    showSizeChanger: true,
                  }}
                  bordered
                  rowKey={(record) => record.Id || 0}
                  columns={columns}
                  size="small"
                  dataSource={filteredDataSource}
                  onHeaderRow={() => ({
                    className:
                      'text-[12px] !text-[#7E7E7E] not-italic !font-normal leading-[20px] tracking-wide text-left !bg-[white]',
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}
