import { ReactElement, useEffect, useState } from 'react'
import { Avatar, Dropdown } from 'antd'
import { useNavigate } from 'react-router-dom'
import { fetchUser } from '../../services/accounts'
import { User } from '../../models/manager/user'
import { EventBus } from '../../utils/EventBus'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfileContext } from '../../contexts/profileContext'
import { ExtendedMenuItem, filterMenuItems } from '../../utils/extendedMenu'
import { Roles } from '../../constants/common'

interface AppHeaderProfileProps {
  context: string
}
const AUTH0_RETURN_URL = process.env.REACT_APP_AUTH0_RETURN_URL

export function AppHeaderProfile(props: AppHeaderProfileProps): ReactElement {
  const navigate = useNavigate()
  const { profile, saveProfile } = useProfileContext()

  const [user, setUser] = useState<User>()
  const [visible, setVisible] = useState(false)
  const { logout, getAccessTokenSilently } = useAuth0()

  const goToProfile = () => {
    navigate('/user-profile')
  }

  const goToAccountBilling = () => {
    navigate('/account-billing')
  }

  const goToStopImpersonating = () => {
    saveProfile({ ...profile, IsImpersonating: false })
    window.location.reload()
  }

  function setAccount(accountId: number) {
    saveProfile({
      ...profile,
      AccountId: accountId,
      AccountName: user?.AccountUsers?.find((x) => x.AccountId == accountId)?.Account?.Name ?? '',
      AccountUid: user?.AccountUsers?.find((x) => x.AccountId == accountId)?.Account?.AccountGuid ?? '',
      Permissions: user?.AccountUsers?.find((x) => x.AccountId == accountId)?.Permissions ?? 0,
      Role: user?.AccountUsers?.find((x) => x.AccountId == accountId)?.RoleId ?? Roles.User,
    })

    // after changing the current profile, page must be refreshed
    window.location.reload()
  }

  const items: ExtendedMenuItem[] = [
    {
      key: '1',
      label: (
        <div className="!max-w-[200px] block">
          <div className="flex items-center space-x-2 p-2 my-1">
            <Avatar size={40} className="bg-PrimaryDarkBlue-100">
              {user?.FirstName?.charAt(0).toLocaleUpperCase()}
              {user?.LastName?.charAt(0).toLocaleUpperCase()}
            </Avatar>
            <span className="flex flex-col">
              <span className="font-semibold">{`${user?.FirstName} ${user?.LastName}`}</span>
              <span
                onClick={goToProfile}
                className="text-credsPrimaryBlue-100 cursor-pointer hover:bg-PrimaryBgWhite-100 px-1 rounded-md"
              >
                Profile
              </span>
            </span>
          </div>
          <div className="text-xs text-gray-500 !max-w-[200px] overflow-hidden text-ellipsis">{user?.Email}</div>
        </div>
      ),
      style: { cursor: 'default' },
      className: 'hover:!bg-white',
    },
    {
      type: 'divider',
      key: 'div-1',
    },
    {
      key: '2',
      label: (
        <div className="flex flex-col gap-3 p-1">
          <span className="text-gray-500 text-sm">Account</span>
          {user &&
            user.AccountUsers?.map((account) => (
              <div
                key={account.AccountId}
                className={` cursor-pointer hover:bg-sky-300 rounded-md px-4 py-1 ${
                  account.AccountId == profile.AccountId ? 'bg-sky-100' : ''
                } flex flex-col`}
                onClick={() => setAccount(account.AccountId)}
              >
                <span>{account.Account?.Name}</span>
                <span>{account.AccountId}</span>
              </div>
            ))}
        </div>
      ),
      style: { cursor: 'default' },
      className: 'hover:!bg-white',
    },
    {
      type: 'divider',
      key: 'div-2',
    },
    {
      key: '3',
      label: (
        <span onClick={goToStopImpersonating} className="text-credsPrimaryBlue-100 cursor-pointer p-1">
          Stop Impersonating
        </span>
      ),
      forImpersonation: true,
    },
    {
      type: 'divider',
      key: 'div-3',
      forImpersonation: true,
    },
    {
      key: '4',
      label: (
        <span onClick={goToAccountBilling} className="text-credsPrimaryBlue-100 cursor-pointer p-1">
          Account & Billing
        </span>
      ),
      userRoles: [Roles.AccountOwner, Roles.SystemAdmin],
    },
    {
      type: 'divider',
      key: 'div-4',
      userRoles: [Roles.AccountOwner, Roles.SystemAdmin],
    },
    {
      key: '5',
      label: (
        <span>
          <div
            className="text-credsPrimaryBlue-100 cursor-pointer p-1"
            onClick={() => {
              localStorage.clear()
              logout({ logoutParams: { returnTo: AUTH0_RETURN_URL } })
            }}
            key="loginPopupAccount"
          >
            Logout
          </div>
        </span>
      ),
    },
  ]

  const handleOpenChange = (flag: boolean) => {
    setVisible(flag)
  }

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      fetchUser(profile.AuthUid, token).then(setUser)
    })
    EventBus.on('updateUser', setUser)

    return () => {
      EventBus.off('updateUser', setUser)
    }
  }, [])

  return props.context === 'desktop' ? (
    <Dropdown
      menu={{ items: filterMenuItems(items, profile) }}
      onOpenChange={handleOpenChange}
      open={visible}
      trigger={['click']}
      placement="bottomRight"
    >
      <div className="cursor-pointer flex items-center gap-2 hover:!text-credsPrimaryBlue-100">
        <Avatar size={32} className="bg-PrimaryDarkBlue-100 !text-sm">
          {user?.FirstName?.charAt(0).toLocaleUpperCase()}
          {user?.LastName?.charAt(0).toLocaleUpperCase()}
        </Avatar>
        <div className="flex gap-1 items-center group">
          <span className="text-base font-normal text-PrimaryDarkBlue-100 group-hover:text-credsPrimaryBlue-100">
            {profile.AccountName}
          </span>
          {visible ? (
            <UpOutlined className="transition-transform duration-600 transform text-PrimaryDarkBlue-100 group-hover:text-credsPrimaryBlue-100" />
          ) : (
            <DownOutlined className="transition-transform duration-600 transform text-PrimaryDarkBlue-100 group-hover:text-credsPrimaryBlue-100" />
          )}
        </div>
      </div>
    </Dropdown>
  ) : (
    <div className="!bg-[#c5c6d030] ml-[-25px] mr-[-25px] pb-[20px] border-t-[1px_solid_silver] cursor-pointer flex items-center gap-2 hover:!text-credsPrimaryBlue-100">
      <div className="ml-[35px]">
        <div className="flex gap-1 items-center group">
          <div>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              filterMenuItems(items, profile).map((item: any) => (
                <div className="mb-5" key={item.key}>
                  <span>{item.label}</span>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}
