import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  FileOutlined,
  PieChartOutlined,
  PrinterOutlined,
  ShoppingOutlined,
} from '@ant-design/icons'
import { Layout, Menu, MenuProps } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { Content, Header } from 'antd/es/layout/layout'
import { ReactElement, ReactNode, useState } from 'react'
import { AdminHeader } from '../header/AdminHeader'
import { Link, useLocation } from 'react-router-dom'
import '../../assets/css/adminModule.css'

interface Props {
  children: ReactNode
}

type MenuItem = Required<MenuProps>['items'][number] & { path?: string }

function getItem(
  label: ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  path?: string
): MenuItem {
  return {
    key,
    icon,
    children,
    label: path ? <Link to={path}>{label}</Link> : label,
    style: !path ? { cursor: 'default' } : undefined,
  } as MenuItem
}

const items: MenuItem[] = [
  getItem('Dashboard', '/admin/dashboard', <PieChartOutlined />, undefined, '/admin/dashboard'),
  getItem('Print Jobs', '2', <PrinterOutlined />, [
    getItem('Job Listing', '/admin/job-listing', undefined, undefined, '/admin/job-listing'),
  ]),
  getItem('Transactions', '3', <ShoppingOutlined />, undefined, undefined),
  getItem('Financial', '4', <FileOutlined />, undefined, undefined),
  getItem('Accounts', '5', <FileOutlined />, undefined, '/admin/account-listing'),
]

export const AdminLayout = ({ children }: Props): ReactElement => {
  const location = useLocation()
  const { pathname } = location
  const [collapsed, setCollapsed] = useState(false)

  return (
    <Layout className="flex flex-col min-h-screen">
      <Header className="flex items-center shadow-lg bg-white p-0 h-auto !w-full">
        <AdminHeader />
      </Header>
      <Layout className="mt-2 h-full">
        <Sider
          trigger={
            <div className="flex justify-end mr-2">
              {collapsed && <DoubleRightOutlined className="transition-all ease-in text-lg" />}
              {!collapsed && <DoubleLeftOutlined className="transition-all ease-in text-lg" />}
            </div>
          }
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={200}
          style={{ overflow: 'auto', height: '100vh', position: 'sticky', left: 0, top: 0, bottom: 0 }}
        >
          <Menu
            selectedKeys={[pathname]}
            defaultSelectedKeys={[pathname]}
            theme="light"
            mode="inline"
            style={{ height: '100%', borderRight: 0 }}
            items={items}
          />
        </Sider>
        <Layout style={{ padding: '0 12px 12px' }}>
          <Content className="bg-PrimaryBgWhite-100 rounded-lg">{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  )
}
