import { Status } from '../../constants/common'
import { Job } from '../../models/manager/job'
import { RecordAuxRequest } from '../../models/manager/record'
import { apiRequest } from '../../utils/axios'

export async function fetchJobs(token: string): Promise<Job[]> {
  return apiRequest('GET', '/admin/jobs', token)
}

export async function fetchJobsById(jobId: number, token: string): Promise<Job> {
  return apiRequest('GET', `/admin/job/${jobId}`, token)
}

export async function patchJob(data: { Id: number; StatusId: Status }, token: string): Promise<number> {
  return apiRequest('PATCH', `/admin/jobStatus/`, token, data)
}

export async function fetchFolderAsZip(folder: string, token: string): Promise<Blob> {
  return apiRequest('GET', `/admin/blobFolderZip/${folder}`, token, null, 'blob')
}

export async function fetchJobLogRecords(jobId: number, token: string): Promise<RecordAuxRequest[]> {
  return apiRequest('GET', `/admin/jobLogRecords/${jobId}`, token)
}
