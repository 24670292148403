import { ReactElement, useEffect, useState } from 'react'
import { AppLayout } from '../components/layout/AppLayout'
import { Col, Divider, Row } from 'antd'
import { fetchPrintAgents } from '../services/printAgents'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfileContext } from '../contexts/profileContext'
import { PrintAgent } from '../models/manager/printAgent'
import { AccountPermissions } from '../constants/common'
import { checkPermission } from '../utils/permissions'
import { SiteButton } from '../components/controls/SiteButton'
import { useNavigate } from 'react-router-dom'
import { PrintAgentHeader } from '../components/localPrint/PrintAgentHeader'
import PrintImg from '../assets/images/localPrint/print.png'
import { AgentCard } from '../components/localPrint/AgentCard'
import { LoadingSpinner } from '../components/shared/LoadingSpinner'
import { useTitle } from '../hooks/useTitle'

export default function LocalPrinting(): ReactElement {
  useTitle('Local Printing')
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfileContext()
  const navigate = useNavigate()
  const [agents, setAgents] = useState<PrintAgent[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    navigate('/')
  }

  useEffect(() => {
    setIsLoading(true)
    getAccessTokenSilently().then((token) => {
      fetchPrintAgents(profile.AccountId, token)
        .then(setAgents)
        .finally(() => {
          setIsLoading(false)
        })
    })
  }, [])

  return (
    <AppLayout>
      {isLoading && <LoadingSpinner isLoading={isLoading} label="Loading..." />}
      <div className="container mx-auto">
        <div className="flex justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6">
          <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Local Printing</h1>
          <SiteButton
            onClick={handleClose}
            label={'Close'}
            id="createSet"
            disabled={!checkPermission(AccountPermissions.CanCreatePrintJobs, profile.Permissions)}
          />
        </div>
        <div className="flex-col justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6 my-2 py-1">
          <PrintAgentHeader numberOfAgents={agents.length} />
          <Divider className="border-BorderLightGrey-100 m-0" />

          {agents && agents.length === 0 && (
            <div className="my-2">
              <Row gutter={[24, 24]}>
                <Col xs={24} md={6}>
                  <div className="flex justify-center items-center p-4 border-credsPrimaryBlue-100 bg-SecondaryLightBlue-100 border rounded-lg">
                    <div className="flex flex-col items-center">
                      <img src={PrintImg} alt="Printer" className="w-32 h-32 mb-4" />
                      <span className="text-lg font-semibold">Waiting to install</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <div className="my-2">
            <Row gutter={[24, 24]}>
              {agents &&
                agents.map((agent) => (
                  <Col xs={24} md={6} key={agent.Id}>
                    <AgentCard printAgent={agent} />
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}
