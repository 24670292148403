import { Skeleton, Table } from 'antd'
import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../../components/shared/LoadingSpinner'
import { errorHandler } from '../../../utils/helper'
import { useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { ShipmentDetails } from '../../models/admin'
import { fetchShipmentsByAccountId } from '../../../adminModule/services/adminShipment'
import { useAuth0 } from '@auth0/auth0-react'

interface Props {
  accountId: number
}

export default function AdminAccountShipments({ accountId }: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [shipmentData, setShipmentData] = useState<ShipmentDetails[]>([])

  const columns: ColumnsType<ShipmentDetails> = [
    {
      key: 'id',
      title: 'Id',
      dataIndex: 'Id',
      width: 60,
      sorter: (a, b) => (a.Id || 0) - (b.Id || 0),
      render: (id: number) => {
        return <div className="pr-2 pl-2">{id}</div>
      },
    },
    {
      key: 'name',
      title: 'Shipper',
      dataIndex: 'Name',
      width: 100,
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (name) => {
        return (
          <div className="flex flex-nowrap justify-start items-center gap-1">
            <div className="flex-[1] px-[8px] py-px gap-[4px] cursor-pointer flex-nowrap">
              <div className="not-italic font-normal pr-2 pl-2 overflow-hidden overflow-ellipsis w-[fit-content] whitespace-nowrap max-w-[15ch]">
                {name}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      key: 'jobId',
      title: 'Job Id',
      dataIndex: 'JobId',
      sorter: (a, b) => (a.JobId || 0) - (b.JobId || 0),
      render: (jobId: string) => {
        return <div className="pr-2 pl-2">{jobId}</div>
      },
    },
    {
      title: 'Provider',
      dataIndex: 'Provider',
      key: 'Provider',
      sorter: (a, b) => a.Provider.localeCompare(b.Provider),
      render: (Provider: string) => {
        return <div className="pr-2 pl-2">{Provider}</div>
      },
    },
    {
      title: 'Tracking Number',
      dataIndex: 'TrackingNumber',
      key: 'trackingNumber',
      sorter: (a, b) => a.TrackingNumber.localeCompare(b.TrackingNumber),
      render: (TrackingNumber: string) => {
        return <div className="pr-2 pl-2">{TrackingNumber}</div>
      },
    },
    {
      title: 'Service Level',
      dataIndex: 'ServiceLevel',
      key: 'serviceLevel',
      sorter: (a, b) => a.ServiceLevel?.localeCompare(b.ServiceLevel || '') || 0,
      render: (ServiceLevel: string) => {
        return <div className="pr-2 pl-2">{ServiceLevel}</div>
      },
    },
    {
      title: 'Estimated Days',
      dataIndex: 'EstimatedDays',
      key: 'estimatedDays',
      sorter: (a, b) => a.EstimatedDays?.toString().localeCompare(b.EstimatedDays?.toString() || '') || 0,
      render: (EstimatedDays: string) => {
        return <div className="pr-2 pl-2">{EstimatedDays}</div>
      },
    },
    {
      title: 'Created',
      dataIndex: 'CreatedAt',
      key: 'Created',
      sorter: (a, b) => (dayjs(a.CreatedAt).isBefore(b.CreatedAt) ? -1 : 1),
      render: (Created: string) => {
        return <div className="pr-2 pl-2">{dayjs(Created).format('YYYY-MM-DD')}</div>
      },
      width: 100,
    },
    {
      title: 'Created By',
      dataIndex: 'CreatedBy',
      key: 'CreatedBy',
      render: (createdBy: string) => {
        return <div className="pr-2 pl-2 pt-1 pb-1">{createdBy}</div>
      },
    },
  ]

  const onRowSelected = (shipment: ShipmentDetails, event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    event?.stopPropagation()
    navigate(`/admin/job-detail?jobId=${shipment.JobId}`)
  }

  useEffect(() => {
    getAccessTokenSilently().then(async (token) => {
      fetchShipmentsByAccountId(accountId, token)
        .then(setShipmentData)
        .catch(errorHandler)
        .finally(() => setIsLoading(false))
    })
  }, [])

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      {isLoading && <LoadingSpinner isLoading={isLoading} label="Loading..." />}
      {shipmentData.length > 0 && (
        <div className="flex-col flex-wrap">
          <div className="adminContainerRow">
            <div className="adminContainer">
              <div id="adminAccountShipments" className="mt-1">
                {isLoading ? (
                  <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
                ) : (
                  <Table<ShipmentDetails>
                    id="view-shipments"
                    pagination={{
                      pageSizeOptions: ['10', '20', '30', '50'],
                      showSizeChanger: true,
                    }}
                    bordered
                    className="adminTableRow"
                    rowKey={(record) => record.Id}
                    columns={columns}
                    size="small"
                    dataSource={shipmentData}
                    onRow={(record) => {
                      return {
                        onClick: (e) => {
                          onRowSelected(record, e)
                        },
                      }
                    }}
                    onHeaderRow={() => ({
                      className:
                        'text-[12px] !text-[#7E7E7E] not-italic !font-normal leading-[20px] tracking-wide text-left !bg-[white]',
                    })}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Suspense>
  )
}
