import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import EmptyLoading from '../pages/auth/EmptyLoading'

export const PublicRoutes: FC = () => {
  return (
    <>
      <div>
        <Routes>
          <Route path="/loading" element={<EmptyLoading />} />
          <Route path="/*" element={<Navigate to="/home" replace />} />
        </Routes>
      </div>
    </>
  )
}
