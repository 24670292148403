import { Account } from '../../models/manager/account'
import { AccountUser } from '../../models/manager/accountUser'
import { apiRequest } from '../../utils/axios'

export async function fetchAccounts(token: string): Promise<Account[]> {
  return apiRequest('GET', '/admin/accounts', token)
}

export async function fetchUsersByAccountId(accountId: number, token: string): Promise<AccountUser[]> {
  return apiRequest('GET', `/admin/usersByAccountId/${accountId}`, token)
}
