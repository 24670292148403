import { ReactElement, ReactNode, Suspense, useEffect, useState } from 'react'
import { useTitle } from '../../hooks/useTitle'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/shared/LoadingSpinner'
import { Button, Input, Skeleton, Space, Table } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import { errorHandler } from '../../utils/helper'
import { AdminButton } from '../components/controls/AdminButton'
import { fetchJobLogRecords } from '../services/adminJob'
import { fetchFieldSet } from '../../services/records'
import { FieldSet } from '../../models/manager/fieldset'
import { FieldType } from '../../constants/common'
import { ImageCell } from '../../components/shared/ImageCell'
import { FilterDropdownProps } from 'antd/es/table/interface'
import { SearchOutlined } from '@ant-design/icons'

interface RecordsColumns {
  title: string
  dataIndex: number | string
  key: number | string
  width?: number | string
  filterDropdown?: (props: FilterDropdownProps) => ReactNode
  onFilter?: (value: string | number | boolean, record: Record<string, string>) => boolean
  filteredValue?: string[] | null
  sorter?: (a: Record<string, string>, b: Record<string, string>) => number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (record: Record<string, any>) => ReactElement
  fixed?: boolean | 'left' | 'right'
}

export default function AdminJobLogListing(): ReactElement {
  useTitle('Job Log Listing')
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const jobId = parseInt(searchParams.get('jobId') ?? '0')
  const recordSetId = parseInt(searchParams.get('recordSetId') ?? '0')
  const userAccountUid = searchParams.get('userAccountUid') ?? ''

  const [isLoading, setIsLoading] = useState(false)

  // Type Any is used here because of we don't know how the data looks like
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [recordsData, setRecordsData] = useState<Record<string, any>[]>([])
  const [columns, setColumns] = useState<RecordsColumns[]>([])

  const getColumns = async () => {
    try {
      // job log id column
      const jobLogIdColumn: RecordsColumns = {
        title: 'Job Log ID',
        dataIndex: '__jl_id',
        key: 'jobLogId',
        fixed: 'left',
      }

      // pdf name column
      const pdfNameColumn: RecordsColumns = {
        title: 'PDF Name',
        dataIndex: '__cn_id',
        key: 'PdfName',
        render: (pdfName) => {
          return (
            <div className="flex justify-between items-center">
              {JSON.stringify(pdfName) != '{}' ? `${pdfName}.pdf` : 'blank.pdf'}
            </div>
          )
        },
      }
      const response = await fetchFieldSet(recordSetId, await getAccessTokenSilently())
      if (response.length > 0) {
        const dynamicColumns: RecordsColumns[] = response.map((item: FieldSet) => {
          const { Name, TypeId } = item

          let sorterFunction: ((a: Record<string, string>, b: Record<string, string>) => number) | undefined
          let renderFunction: ((a: Record<string, string>) => ReactElement) | undefined
          if (TypeId === FieldType.Date) {
            sorterFunction = (a, b) => {
              const dataIndex = item.Name
              if (a[dataIndex]) {
                return a[dataIndex].localeCompare(b[dataIndex])
              }
              return 0
            }
          }

          if (TypeId === FieldType.Image) {
            renderFunction = (record) => {
              return (
                <div className="flex justify-between items-center">
                  <ImageCell
                    record={record}
                    item={item}
                    recordSetId={recordSetId}
                    fieldSetId={response.find((f) => f.Name == item.Name)?.Id || 0}
                    refresh={refresh}
                    showChangeButton={false}
                    accountUid={userAccountUid}
                  />
                </div>
              )
            }
          }

          //TODO: could add the image to the image link column ie. make a preview
          // if (TypeId === FieldType.ImageLink) {
          //   renderFunction = (record) => {
          //     return (
          //       <div className="flex justify-between items-center">
          //         <ImageCell
          //           record={record}
          //           item={item}
          //           recordSetId={recordSetId}
          //           fieldSetId={response.find((f) => f.Name == item.Name)?.Id || 0}
          //           refresh={refresh}
          //           showChangeButton={false}
          //           accountUid={userAccountUid}
          //         />
          //       </div>
          //     )
          //   }
          // }

          return {
            title: Name,
            dataIndex: TypeId === FieldType.Image ? '' : Name,
            key: Name,
            filterSearch: true,
            width: 300,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Search ${item.Name}`}
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => handleSearch(confirm)}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    className="text-white hover:!text-white bg-credsPrimaryBlue-100 hover:bg-PrimaryDarkBlue-100 inline-flex items-center"
                    onClick={() => handleSearch(confirm)}
                    icon={<SearchOutlined className="leading-[0]" />}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      if (clearFilters) clearFilters()
                      handleSearch(confirm)
                    }}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            onFilter: (value, record) => {
              const dataIndex = Name
              return record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase())
                : false
            },
            render: renderFunction,
            sorter: sorterFunction,
          }
        })
        dynamicColumns.unshift(jobLogIdColumn, pdfNameColumn)
        setColumns(dynamicColumns)
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  const handleClose = () => {
    navigate(-1)
  }

  const getRecordData = async () => {
    setIsLoading(true)
    try {
      const response = await fetchJobLogRecords(jobId, await getAccessTokenSilently())
      setRecordsData(response)
      setIsLoading(false)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearch = (confirm: () => void) => {
    confirm()
  }

  const refresh = () => {
    getColumns()
    getRecordData()
  }

  useEffect(() => {
    setIsLoading(true)
    getColumns()
    getRecordData()
    setIsLoading(false)
  }, [])

  return (
    <Suspense fallback={<LoadingSpinner isLoading={isLoading} label="Loading..." />}>
      <div className="container mx-auto">
        <div className="flex justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6 my-2 py-2">
          <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Print Job List for Job: {jobId}</h1>
          <AdminButton label={'Close'} onClick={handleClose} />
        </div>
        {isLoading ? (
          <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
        ) : (
          <Table
            id="jobLogListing"
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              showSizeChanger: true,
            }}
            scroll={{ x: true }}
            bordered
            rowKey={(data) => data.__jl_id ?? 0}
            columns={columns}
            size="small"
            dataSource={recordsData}
            onHeaderRow={() => ({
              className:
                'text-[12px] !text-[#7E7E7E] not-italic !font-normal leading-[20px] tracking-wide text-left !bg-[white]',
            })}
          />
        )}
      </div>
    </Suspense>
  )
}
