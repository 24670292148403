import { EventData, EventRequest } from '../models/manager/event'
import { apiRequest } from '../utils/axios'

export async function fetchEvents(accountId: number, token: string): Promise<EventData[]> {
  return apiRequest('GET', `/events/${accountId}`, token)
}

export async function fetchEvent(eventId: number, token: string): Promise<EventData> {
  return apiRequest('GET', `/event/${eventId}`, token)
}

export async function addEvent(event: EventRequest, token: string): Promise<EventData> {
  return apiRequest('POST', '/event', token, event)
}

export async function updateEvent(event: EventRequest, token: string): Promise<EventData> {
  return apiRequest('PUT', '/event', token, event)
}

export async function deleteEvent(eventId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/event/${eventId}`, token)
}
