import { Skeleton, Table } from 'antd'
import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../../components/shared/LoadingSpinner'
import { errorHandler } from '../../../utils/helper'
import { ColumnsType } from 'antd/es/table'
import { fetchAddresses } from '../../../services/addresses'
import { useAuth0 } from '@auth0/auth0-react'
import { AddressRequest, Region } from '../../../models/manager/address'

interface Props {
  accountId: number
}

export default function AdminAccountAddresses({ accountId }: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const [addressData, setAddressData] = useState<AddressRequest[]>([])

  const columns: ColumnsType<AddressRequest> = [
    {
      title: 'Full Name',
      dataIndex: 'FullName',
      key: 'FullName',
      render: (FullName: number) => {
        return <div className="pr-2 pl-2">{FullName}</div>
      },
    },
    {
      key: 'IsDefault',
      title: 'Default',
      dataIndex: 'IsDefault',
      render: (IsDefault: boolean) => {
        return (
          <div className="flex flex-[1] flex-nowrap whitespace-nowrap not-italic font-normal pr-2 pl-2">
            {IsDefault ? 'Yes' : 'No'}
          </div>
        )
      },
    },
    {
      key: 'Company',
      title: 'Company',
      dataIndex: 'Company',
      render: (Company: string) => {
        return <div className="pr-2 pl-2">{Company}</div>
      },
    },
    {
      title: 'Address 1',
      dataIndex: 'Address1',
      key: 'Address1',
      render: (Address1: string) => {
        return <div className="pr-2 pl-2 whitespace-nowrap">{Address1}</div>
      },
    },
    {
      title: 'Address 2',
      dataIndex: 'Address2',
      key: 'Address2',
      render: (Address2: string) => {
        return <div className="pr-2 pl-2 whitespace-nowrap">{Address2}</div>
      },
    },
    {
      title: 'City',
      dataIndex: 'City',
      key: 'City',
      render: (City: string) => {
        return <div className="pr-2 pl-2">{City}</div>
      },
    },
    {
      title: 'Region',
      dataIndex: 'Region',
      key: 'Region',
      render: (region: Region) => {
        return <div className="pr-2 pl-2">{region.Name}</div>
      },
    },
    {
      title: 'ZipPostal',
      dataIndex: 'ZipPostal',
      key: 'ZipPostal',
      render: (ZipPostal: string) => {
        return <div className="pr-2 pl-2">{ZipPostal}</div>
      },
    },
    {
      title: 'Country',
      dataIndex: 'Region',
      key: 'Country',
      render: (region: Region) => {
        return <div className="pr-2 pl-2 pt-1 pb-1">{region.Country?.Name}</div>
      },
    },
  ]

  useEffect(() => {
    getAccessTokenSilently().then(async (token) => {
      fetchAddresses(accountId, token)
        .then(setAddressData)
        .catch(errorHandler)
        .finally(() => setIsLoading(false))
    })
  }, [])

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      {isLoading && <LoadingSpinner isLoading={isLoading} label="Loading..." />}
      <div className="flex-col flex-wrap">
        <div className="adminContainerRow">
          <div className="adminContainer">
            <div id="adminAccountAddresses" className="mt-1">
              {isLoading ? (
                <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
              ) : (
                <Table<AddressRequest>
                  id="view-addresses"
                  pagination={{
                    pageSizeOptions: ['10', '20', '30', '50'],
                    showSizeChanger: true,
                  }}
                  bordered
                  rowKey={(record) => record.Id || 0}
                  columns={columns}
                  size="small"
                  dataSource={addressData}
                  onHeaderRow={() => ({
                    className:
                      'text-[12px] !text-[#7E7E7E] not-italic !font-normal leading-[20px] tracking-wide text-left !bg-[white]',
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}
