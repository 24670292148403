import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react'
import { AppHeader } from './AppHeader'
import { AppFooter } from './AppFooter'
import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthLayout } from './Auth/AuthLayout'
import { SignupInfo } from '../shared/SignupInfo'
import { useProfileContext } from '../../contexts/profileContext'
import { PlanType } from '../../constants/common'
import { Account } from '../../models/manager/account'
import { fetchAccount, updateAccount } from '../../services/accounts'
import { errorHandler } from '../../utils/helper'

interface Props {
  children?: ReactNode
  headless?: boolean
}

export function AppLayout({ children, headless }: Props): ReactElement {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const { profile, saveProfile } = useProfileContext()
  const { Header, Footer } = Layout
  const { getAccessTokenSilently } = useAuth0()

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect()
    }

    if (localStorage.getItem('p') != null) {
      if (!profile.IsProfileComplete) {
        saveProfile({ ...profile, IsProfileComplete: false })
        setIsProfileModalOpen(true)
      } else {
        saveProfile({ ...profile, IsProfileComplete: true })
      }
    }

    if (profile.IsProfileComplete) {
      if (!profile.IsPlanComplete) {
        getAccessTokenSilently().then(async (token) => {
          fetchAccount(profile.AccountId, token).then(async (userAccount) => {
            if (userAccount) {
              const accountData: Account = {
                ...userAccount,
                PlanId: PlanType.Essentials,
                PlanStartDate: new Date(),
              }
              updateAccount(accountData, await getAccessTokenSilently())
                .then(() => {
                  saveProfile({ ...profile, IsPlanComplete: true })
                })
                .catch(errorHandler)
            }
          })
        })
      }
    }
  }, [])

  const modalStyle = `mainLayout !bg-PrimaryBgWhite-100 ${!headless ? 'min-h-screen' : ''}`

  return (
    <Fragment>
      {!profile.IsProfileComplete ? (
        <AuthLayout>
          <SignupInfo isOpen={isProfileModalOpen} />
        </AuthLayout>
      ) : (
        <Layout className={modalStyle}>
          {!headless && (
            <Header>
              <AppHeader />
              <div className="h-[10px]" />
            </Header>
          )}
          {isAuthenticated && <Content className="h-auto flex-1 mt-6">{children}</Content>}
          {!headless && (
            <Footer>
              <AppFooter />
            </Footer>
          )}
        </Layout>
      )}
    </Fragment>
  )
}
