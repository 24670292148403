import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { ProfileProvider } from './contexts/profileContext'
import ReactGA from 'react-ga4'
import { sprig } from '@sprig-technologies/sprig-browser'
import TagManager from 'react-gtm-module'

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ?? ''
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE ?? ''

const tracker = process.env.REACT_APP_GA_TRACKING_ID ?? ''
ReactGA.initialize(tracker)
const trackers = [
  {
    trackingId: process.env.REACT_APP_GA_TRACKING_ID ?? '',
    gaOptions: { name: 'tracker1' },
  },
]
ReactGA.initialize(trackers)

export const Sprig = sprig.configure({
  environmentId: process.env.REACT_APP_SPRIG_ENV_ID || '',
})

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GA_TRACKING_TAG_ID || '',
}
TagManager.initialize(tagManagerArgs)

// get the mode parameter from the querystring
const urlParams = new URLSearchParams(window.location.search)
const mode = urlParams.get('mode')
let loginHint = ''
let signupHint = ''
const redirectUri = window.location.origin

if (mode === '' || mode === 'undefined') {
  loginHint = 'login'
  signupHint = ''
} else if (mode === 'signup') {
  loginHint = ''
  signupHint = 'signup'
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: AUTH0_AUDIENCE,
        loginHint: loginHint,
        screen_hint: signupHint,
      }}
    >
      <ProfileProvider>
        <App />
      </ProfileProvider>
    </Auth0Provider>
  </React.StrictMode>
)
