import { Avatar, Dropdown, MenuProps } from 'antd'
import { ReactElement, useState } from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useProfileContext } from '../../../contexts/profileContext'
import { useAuth0 } from '@auth0/auth0-react'

const AUTH0_RETURN_URL = process.env.REACT_APP_AUTH0_RETURN_URL

export const AdminProfile = (): ReactElement => {
  const { profile } = useProfileContext()
  const { logout } = useAuth0()

  const [visible, setVisible] = useState(false)

  const handleOpenChange = (flag: boolean) => {
    setVisible(flag)
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className="flex items-center space-x-2 p-2 my-1">
          <Avatar size={40} className="bg-PrimaryDarkBlue-100">
            {profile.FirstName?.charAt(0).toLocaleUpperCase()}
            {profile.LastName?.charAt(0).toLocaleUpperCase()}
          </Avatar>
          <span className="flex flex-col">
            <span className="font-semibold">{`${profile.FirstName} ${profile.LastName}`}</span>
            <span className="text-sm text-gray-500">{profile.Email}</span>
          </span>
        </div>
      ),
      style: { cursor: 'default' },
      className: 'hover:!bg-white',
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: (
        <span>
          <div
            className="text-credsPrimaryBlue-100 cursor-pointer p-1"
            onClick={() => {
              localStorage.clear()
              logout({ logoutParams: { returnTo: AUTH0_RETURN_URL } })
            }}
            key="loginPopupAccount"
          >
            Logout
          </div>
        </span>
      ),
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
      onOpenChange={handleOpenChange}
      open={visible}
      trigger={['click']}
      placement="bottomRight"
    >
      <div className="cursor-pointer flex items-center gap-2 hover:!text-credsPrimaryBlue-100">
        <Avatar size={32} className="bg-PrimaryDarkBlue-100 !text-sm">
          {profile.FirstName?.charAt(0).toLocaleUpperCase()}
          {profile.LastName?.charAt(0).toLocaleUpperCase()}
        </Avatar>
        <div className="flex gap-1 items-center group">
          <span className="text-base font-normal text-PrimaryDarkBlue-100 group-hover:text-credsPrimaryBlue-100">
            {`${profile.FirstName} ${profile.LastName}`}
          </span>
          {visible ? (
            <UpOutlined className="transition-transform duration-600 transform text-PrimaryDarkBlue-100 group-hover:text-credsPrimaryBlue-100" />
          ) : (
            <DownOutlined className="transition-transform duration-600 transform text-PrimaryDarkBlue-100 group-hover:text-credsPrimaryBlue-100" />
          )}
        </div>
      </div>
    </Dropdown>
  )
}
