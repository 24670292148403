export enum Status {
  Draft = 1,
  InProgress = 2,
  Printing = 3,
  Printed = 4,
  Shipped = 5,
  Received = 6,
  Cancelled = 7,
}

export enum UserStatusEnum {
  Invited = 1,
  Active = 2,
  Disabled = 3,
}

export enum PlanType {
  Essentials = 1,
  Professional = 2,
  Enterprise = 3,
  FreeTrial = 4,
  NoneSelected = 5,
}

export enum Orientation {
  Portrait = 1,
  Landscape = 2,
}

export enum CardSize {
  Cr80 = 1,
  Large = 2,
}

export enum Roles {
  SystemAdmin = 1,
  AccountOwner = 2,
  User = 3,
}

export enum FieldType {
  Text = 1,
  Number = 2,
  Date = 3,
  Image = 4,
  QRCode = 5,
  Code128 = 6,
  Code39 = 7,
  EAN8 = 8,
  EAN16 = 9,
  UPC = 10,
  ImageLink = 11,
}

export enum AccountPermissions {
  None = 0,
  CanCreatePrintJobs = 1,
  CanCreateTemplates = 2,
  CanCreateLists = 4,
  CanCreateAddresses = 8,
  CanReloadAccount = 16,
  All = 255,
}

export enum EventFeatures {
  Dashboard = 1,
  Admin = 2,
  Kiosk = 4,
  Reports = 8,
  Integrations = 16,
}

export enum LocalStorageItems {
  TemplateLibraryViewPreference = 'templateLibraryViewPreference',
  ShowCancelledStatus = 'ShowCancelledStatus',
  SelectedAgentId = 'selectedAgentId',
  EventId = 'eventId',
  EventName = 'eventName',
  EventDate = 'eventDate',
  EventShortName = 'eventShortName',
}

export enum AuthRoles {
  Admin = 'admins',
  Onsite = 'onsite',
}

export enum SessionStorageItems {
  TemplateRecordSetId = 'templateRecordSetId',
}

export enum TemplateTypeEnum {
  Cr80Landscape = 1,
  Cr80Portrait = 2,
  LargePortrait = 3,
  FourByThree = 4,
  FourByFive = 5,
  FourByTen = 6,
  FourByTwelve = 7,
  LetterPortrait = 8,
  LetterLandscape = 9,
}

export enum TransactionType {
  PrintJob = 1,
  Plan = 2,
  Purchase = 3,
  ManualRefund = 4,
  ManualCredit = 5,
  Shipping = 6,
}

export enum Country {
  Canada = 1,
  UnitedStates = 2,
}

export enum ShippingType {
  GoShippo = 1,
  LetterMailUsa = 2,
  LetterMailCanada = 3,
  UserAccountFedEx = 4,
  UserAccountUps = 5,
  UserAccountUsps = 6,
  UserAccountDhl = 7,
  UserAccountApc = 8,
  UserAccountCanadaPost = 9,
  UserAccountPurolator = 10,
}

export enum StarterTemplateBreakpoints {
  xl = 1589,
  lg = 1294,
  md = 1023,
  sm = 767,
}

export enum EventType {
  TradeShow = 1,
  Conference = 2,
  Summit = 3,
  Workshop = 4,
  Seminar = 5,
  Bootcamp = 6,
  Symposium = 7,
  Hackathon = 8,
  AwardsGala = 9,
  Auction = 10,
  Fundraiser = 11,
}

export const listImportMimeTypes: string[] = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const TEMPLATE_GUIDELINE_DIMENSIONS = {
  CR80Landscape: {
    dimensions: { w: 1014, h: 639 },
    physicalDimensions: '3.375" x 2.125"',
    guideline: { x: 0, y: 0, w: 1014, h: 639, optional: false, default: true, show: true },
    notches: {
      'notch-1': { x: 422, y: 42, w: 170, h: 32, optional: true, default: true, show: true },
      'notch-2': { x: 422, y: 538, w: 170, h: 32, optional: true, default: true, show: false },
    },
  },
  CR80Portrait: {
    dimensions: { w: 639, h: 1014 },
    physicalDimensions: '2.125" x 3.375"',
    guideline: { x: 0, y: 0, w: 639, h: 1014, optional: false, default: true, show: true },
    notches: {
      'notch-1': { x: 245.7, y: 46.5, w: 156.5, h: 31, optional: true, default: true, show: true },
      'notch-2': { x: 244, y: 910, w: 160, h: 31, optional: true, default: true, show: false },
    },
  },
  XXLPortrait: {
    dimensions: { w: 1038, h: 1653 },
    physicalDimensions: '3.5" x 5.5"',
    guideline: { x: 0, y: 0, w: 1038, h: 1653, optional: false, default: true, show: true },
    notches: {
      'notch-1': { x: 127, y: 66, w: 162, h: 31, optional: false, default: true, show: true },
      'notch-2': { x: 740, y: 63, w: 162, h: 31, optional: false, default: true, show: true },
    },
  },
}

export const QR_PLACEHOLDER = 'https://app.credsnow.com'

export const IMAGE_PLACEHOLDER =
  'https://sacredsb2cstorage.blob.core.windows.net/logintemplatecontainer/app_credsnow_com/icon_dynamic_image.svg'

export const EMPTY_PLACEHOLDER =
  'https://sacredsb2cstorage.blob.core.windows.net/logintemplatecontainer/app_credsnow_com/1x1.png'

export const TEMPLATE_THUMBNAIL_HEIGHT_INLINE_GRID_MAX = 225
export const TEMPLATE_THUMBNAIL_FOLDER = '/thumbnails'
export const STARTER_TEMPLATE_THUMBNAILS_BLOB_FOLDER = 'starter-templates'

export const EVENT_IMAGE_FOLDER = 'event-images'

export const STARTER_TEMPLATES_SINGLE_ROW_HEIGHT = 350
export const MINI_THUMBNAIL_TEMPLATE_PREVIEW_SIZE = 50
export const MINI_THUMBNAIL_TEMPLATE_QUICKPRINT_PREVIEW_SIZE = 75

export const FREE_TRIAL_DAYS = 14

export const MAX_RECORDS_PER_IMPORT = 1001

export const MAX_DESIGN_DESCRIPTION_CHARACTERS = 2500
export const MAX_DESIGN_UPLOAD_FILE_SIZE_MB = 50
export const MAX_DESIGN_UPLOAD_TOTAL_SIZE_MB = 100
export const MAX_DESIGN_UPLOAD_FILECOUNT = 25

export const RENDERING_DPI = 300
export const RENDERING_PIXEL_RATIO = 4

export const COLLATERAL_DOMAIN = 'https://sacredsb2cstorage.blob.core.windows.net'
export const COLLATERAL_REPO = 'collateral'
export const COLLATERAL_PREFIX = 'credsnow'

export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const SHIPPING_ESTIMATE_REFRESH = 60000
export const SHIPPING_CUTOFF_TIME_EST = 16
export const SHIPPING_CUTOFF_LOCALE = 'en-US'
export const SHIPPING_TZ_LOCALE = 'America/New_York'
export const SHIPPING_CUTOFF_GMT_OFFSET = 4
export const SHIPPING_DEPOT_ADDRESS = {
  Name: 'CredsNow',
  Street1: '4301 Jenny Lane',
  City: 'Hernando',
  State: 'Mississippi',
  Zip: '38632',
  Country: 'US',
}

export const SG_CONTACT_LIST_ID = 'cf7d389f-4fc9-455a-ac8e-17c72ca9fa79'
