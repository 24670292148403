import { Timeline, TimelineItemProps } from 'antd'
import { ReactElement } from 'react'
import { Job } from '../../models/manager/job'
import { ShippingTrackResponse } from '../../models/manager/shipping'
import { Status } from '../../constants/common'
import dayjs from 'dayjs'
import { CheckCircleOutlined, HomeOutlined } from '@ant-design/icons'

interface Props {
  currentJob?: Job
  trackShipment?: ShippingTrackResponse
}

export function DeliveryTimeLine({ currentJob, trackShipment }: Props): ReactElement {
  const getColorFromStatus = (step: number): string => {
    if (!trackShipment && step !== 1) return 'grey'

    if (step === 1) {
      if (currentJob?.StatusId === Status.Cancelled) return 'grey'
      if (currentJob?.StatusId === Status.InProgress) return 'blue'
      if (currentJob && currentJob?.StatusId > Status.InProgress) return 'green'
    }
    if (step === 2) {
      if (
        (trackShipment?.TrackingStatus?.Status != null && trackShipment?.TrackingStatus?.Status == 'DELIVERED') ||
        trackShipment?.TrackingStatus?.Status == 'TRANSIT'
      )
        return 'green'
      if (currentJob?.StatusId === Status.Printing || currentJob?.StatusId === Status.Printed) return 'blue'
    }
    if (step === 3) {
      if (
        trackShipment?.TrackingStatus?.Status != null &&
        trackShipment?.TrackingStatus?.Status == 'TRANSIT' &&
        trackShipment?.TrackingStatus?.SubStatus != 'out_for_delivery'
      ) {
        return 'blue'
      }
      if (
        (trackShipment?.TrackingStatus?.Status != null &&
          trackShipment?.TrackingStatus?.Status == 'TRANSIT' &&
          trackShipment?.TrackingStatus?.SubStatus == 'out_for_delivery') ||
        (trackShipment?.TrackingStatus?.Status != null && trackShipment?.TrackingStatus.Status == 'DELIVERED')
      ) {
        return 'green'
      }
    }
    if (step === 4) {
      if (
        trackShipment?.TrackingStatus?.Status != null &&
        trackShipment?.TrackingStatus?.Status == 'TRANSIT' &&
        trackShipment?.TrackingStatus?.SubStatus == 'out_for_delivery'
      ) {
        return 'blue'
      }
      if (trackShipment?.TrackingStatus?.Status != null && trackShipment?.TrackingStatus.Status == 'DELIVERED')
        return 'green'
    }
    return 'grey'
  }
  const isStepPending = (step: number): boolean => {
    //false -> line active
    if (!trackShipment || currentJob?.StatusId === Status.Cancelled) return true
    if (step == 1) {
      if (currentJob && currentJob?.StatusId > Status.InProgress) return false
    }
    if (step == 2) {
      if (
        trackShipment?.TrackingStatus?.Status != null &&
        (trackShipment?.TrackingStatus.Status == 'TRANSIT' ||
          trackShipment?.TrackingStatus.SubStatus == 'out_for_delivery' ||
          trackShipment?.TrackingStatus.Status == 'DELIVERED')
      )
        return false
    }
    if (step == 3) {
      if (
        trackShipment?.TrackingStatus?.Status != null &&
        (trackShipment?.TrackingStatus.Status == 'DELIVERED' ||
          (trackShipment?.TrackingStatus.Status == 'TRANSIT' &&
            trackShipment?.TrackingStatus.SubStatus == 'out_for_delivery'))
      )
        return false
    }
    return true
  }

  const timeLineItems: TimelineItemProps[] = [
    {
      children: 'Print Job in Production',
      label:
        currentJob?.StatusId != Status.InProgress && currentJob?.StatusId != Status.Cancelled
          ? dayjs(currentJob?.CreatedAt).format('MMMM D, YYYY')
          : '                     ',
      color: getColorFromStatus(1),
      dot:
        currentJob?.StatusId != Status.InProgress && currentJob?.StatusId != Status.Cancelled ? (
          <CheckCircleOutlined />
        ) : null,
      pending: isStepPending(1),
      style: { paddingBottom: '50px' },
    },
    {
      children: 'Shipped',
      label:
        trackShipment?.TrackingStatus?.Status != null &&
        (trackShipment?.TrackingStatus.Status == 'TRANSIT' || trackShipment?.TrackingStatus.Status == 'DELIVERED')
          ? dayjs(trackShipment?.TrackingStatus.StatusDate).format('MMMM D, YYYY')
          : '',
      color: getColorFromStatus(2),
      dot:
        trackShipment?.TrackingStatus?.Status != null &&
        (trackShipment?.TrackingStatus.Status == 'TRANSIT' || trackShipment?.TrackingStatus.Status == 'DELIVERED') ? (
          <CheckCircleOutlined />
        ) : null,
      pending: isStepPending(2),
      style: { paddingBottom: '50px' },
    },
    {
      children: 'Out for Delivery',
      label:
        trackShipment?.TrackingStatus?.Status != null &&
        ((trackShipment?.TrackingStatus.Status == 'TRANSIT' &&
          trackShipment?.TrackingStatus.SubStatus == 'out_for_delivery') ||
          trackShipment?.TrackingStatus.Status == 'DELIVERED')
          ? dayjs(trackShipment?.TrackingStatus.StatusDate).format('MMMM D, YYYY')
          : '',
      color: getColorFromStatus(3),
      dot:
        trackShipment?.TrackingStatus?.Status != null &&
        ((trackShipment?.TrackingStatus.Status == 'TRANSIT' &&
          trackShipment?.TrackingStatus.SubStatus == 'out_for_delivery') ||
          trackShipment?.TrackingStatus.Status == 'DELIVERED') ? (
          <CheckCircleOutlined />
        ) : null,
      pending: isStepPending(3),
      style: { paddingBottom: '50px' },
    },
    {
      children: 'Delivered',
      label:
        trackShipment?.TrackingStatus?.Status != null && trackShipment?.TrackingStatus.Status == 'DELIVERED'
          ? dayjs(trackShipment?.TrackingStatus.StatusDate).format('MMMM D, YYYY')
          : '',
      color: getColorFromStatus(4),
      dot: <HomeOutlined />,
      pending: false,
    },
  ]

  return <Timeline className="w-full mt-4" mode="right" items={timeLineItems} />
}
