import { Account, Invite, SgContact, Welcome } from '../models/manager/account'
import { User, UserRequest } from '../models/manager/user'
import { AccountUser } from '../models/manager/accountUser'
import { apiRequest, sendEmailRequest } from '../utils/axios'
import queryString from 'query-string'

export async function fetchAccount(accountId: number, token: string): Promise<Account> {
  return apiRequest('GET', `/account/${accountId}`, token)
}

export async function updateAccount(account: Account, token: string): Promise<void> {
  return apiRequest('PUT', '/account', token, account)
}

export async function saveAccount(account: Account, token: string): Promise<number> {
  return apiRequest('POST', '/account', token, account)
}

export async function fetchUser(authUid: string, token: string): Promise<User> {
  return apiRequest('GET', `/user/${authUid}`, token)
}

export async function fetchUserById(userId: number, token: string): Promise<User> {
  return apiRequest('GET', `/user/${userId}`, token)
}

export async function fetchUsersList(accountId: number, token: string): Promise<AccountUser[]> {
  return apiRequest('GET', `/users/${accountId}`, token)
}

export async function updateUser(user: UserRequest, token: string): Promise<void> {
  return apiRequest('PUT', '/user', token, user)
}

export async function saveUser(user: UserRequest, token: string): Promise<number> {
  return apiRequest('POST', `/user`, token, user)
}

export async function fetchAccountUser(accountId: number, userId: number, token: string): Promise<AccountUser> {
  return apiRequest('GET', `/accountUser/${accountId}/${userId}`, token)
}

export async function updateAccountUser(accountUser: AccountUser, token: string): Promise<void> {
  return apiRequest('PUT', '/accountUser', token, accountUser)
}

export async function saveAccountUser(accountUser: AccountUser, token: string): Promise<void> {
  return apiRequest('POST', '/accountUser', token, accountUser)
}

export async function sendUserInvite(inviteData: Invite, token: string): Promise<void> {
  return sendEmailRequest('GET', `/createInviteEmail?${queryString.stringify(inviteData)}`, token)
}

export async function sendAccountWelcomeEmail(welcomeData: Welcome, token: string): Promise<void> {
  return sendEmailRequest('GET', `/createAccountWelcomeEmail?${queryString.stringify(welcomeData)}`, token)
}

export async function createContact(SgContactInfo: SgContact, token: string): Promise<void> {
  return sendEmailRequest('GET', `/createContact?${queryString.stringify(SgContactInfo)}`, token)
}

export async function getAccountIdFromUid(accountUid: string, token: string): Promise<number> {
  return apiRequest('GET', `/accountId/${accountUid}`, token)
}

export async function getUserIdFromUid(authUid: string, token: string): Promise<number> {
  return apiRequest('GET', `/userId/${authUid}`, token)
}

export async function activateUser(authUid: string, accountId: number, token: string): Promise<User> {
  return apiRequest('GET', `/activateUser/${authUid}/${accountId}`, token)
}

export async function resendVerificationEmail(uid: string, token: string): Promise<void> {
  return sendEmailRequest('GET', `/resendVerificationEmail?${queryString.stringify({ uid: uid })}`, token)
}
