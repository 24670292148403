import { ReactElement } from 'react'
import { Menu } from 'antd'
import { useLocation } from 'react-router-dom'
import { MenuItems } from '../Menu/MenuItems'
import { useProfileContext } from '../../contexts/profileContext'
import { filterMenuItems, transformMenuItem } from '../../utils/extendedMenu'

export function AppHeaderDesktopMenu(): ReactElement {
  const location = useLocation()
  const { pathname } = location
  const { profile } = useProfileContext()
  const transformedMenuItems = filterMenuItems(MenuItems, profile).map(transformMenuItem)

  return (
    <Menu
      selectedKeys={[pathname]}
      defaultSelectedKeys={[pathname]}
      style={{ width: '550px' }}
      items={transformedMenuItems}
      mode="horizontal"
    />
  )
}
