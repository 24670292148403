import { useSearchParams } from 'react-router-dom'
import { ReactElement, Suspense } from 'react'
import { LoadingSpinner } from '../../components/shared/LoadingSpinner'
import { VerticalAlignTopOutlined } from '@ant-design/icons'
import AdminAccountJobListing from '../../adminModule/components/account/AdminAccountJobListing'
import AdminAccountTransactions from '../../adminModule/components/account/AdminAccountTransactions'
import AdminAccountUsers from '../../adminModule/components/account/AdminAccountUsers'
import AdminAccountAddresses from '../../adminModule/components/account/AdminAccountAddresses'
import AdminAccountTemplates from '../../adminModule/components/account/AdminAccountTemplates'
import AdminAccountLists from '../../adminModule/components/account/AdminAccountLists'
import AdminAccountShipments from '../../adminModule/components/account/AdminAccountShipments'

const AdminAccountDetail = (): ReactElement => {
  const [searchParams] = useSearchParams()

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      <div className="adminWrapper">
        <div className="adminHeader">{searchParams.get('accountName') || ''}</div>
        <div className="text-center bg-[#f0f2f5] w-full p-5 rounded-[12px] flex flex-row justify-center self-center flex-wrap">
          <div className="mr-5">
            <a href="#adminJobs">Jobs</a>
          </div>
          <div className="mr-5">
            <a href="#adminShipments">Shipments</a>
          </div>
          <div className="mr-5">
            <a href="#adminTransactions">Transactions</a>
          </div>
          <div className="mr-5">
            <a href="#adminUsers">Users</a>
          </div>
          <div className="mr-5">
            <a href="#adminTemplates">Templates</a>
          </div>
          <div className="mr-5">
            <a href="#adminLists">Lists</a>
          </div>
          <div className="mr-5">
            <a href="#adminAddresses">Addresses</a>
          </div>
        </div>

        <div className="flex-col flex-wrap">
          <div id="adminJobs" className="adminContainerRow">
            <div className="adminContainer">
              <div className="adminContainerHeader">
                Jobs
                <a href="#" className="pl-5">
                  <VerticalAlignTopOutlined />
                </a>
              </div>
              <div id="adminJobDetails" className="mt-1">
                <AdminAccountJobListing accountId={parseInt(searchParams.get('accountId') || '')} />
              </div>
            </div>
          </div>

          <div id="adminShipments" className="adminContainerRow">
            <div className="adminContainer">
              <div className="adminContainerHeader">
                Shipments
                <a href="#" className="pl-5">
                  <VerticalAlignTopOutlined />
                </a>
              </div>
              <AdminAccountShipments accountId={parseInt(searchParams.get('accountId') || '')} />
            </div>
          </div>

          <div id="adminTransactions" className="adminContainerRow">
            <div className="adminContainer">
              <div className="adminContainerHeader">
                Transactions
                <a href="#" className="pl-5">
                  <VerticalAlignTopOutlined />
                </a>
              </div>
              <AdminAccountTransactions accountId={parseInt(searchParams.get('accountId') || '')} />
            </div>
          </div>

          <div id="adminUsers" className="adminContainerRow">
            <div className="adminContainer">
              <div className="adminContainerHeader">
                Users
                <a href="#" className="pl-5">
                  <VerticalAlignTopOutlined />
                </a>
              </div>
              <AdminAccountUsers accountId={parseInt(searchParams.get('accountId') || '')} />
            </div>
          </div>

          <div id="adminTemplates" className="adminContainerRow">
            <div className="adminContainer">
              <div className="adminContainerHeader">
                Templates
                <a href="#" className="pl-5">
                  <VerticalAlignTopOutlined />
                </a>
              </div>
              <AdminAccountTemplates accountId={parseInt(searchParams.get('accountId') || '')} />
            </div>
          </div>

          <div id="adminLists" className="adminContainerRow">
            <div className="adminContainer">
              <div className="adminContainerHeader">
                Lists
                <a href="#" className="pl-5">
                  <VerticalAlignTopOutlined />
                </a>
              </div>
              <AdminAccountLists accountId={parseInt(searchParams.get('accountId') || '')} />
            </div>
          </div>

          <div id="adminAddresses" className="adminContainerRow">
            <div className="adminContainer">
              <div className="adminContainerHeader">
                Addresses
                <a href="#" className="pl-5">
                  <VerticalAlignTopOutlined />
                </a>
              </div>
              <AdminAccountAddresses accountId={parseInt(searchParams.get('accountId') || '')} />
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default AdminAccountDetail
