import { ReactElement } from 'react'
import { AppHeaderLogo } from '../AppHeaderLogo'

export function AuthHeader(): ReactElement {
  return (
    <div className="h-20 flex items-center">
      <AppHeaderLogo />
    </div>
  )
}
