import { Navigate, useLocation } from 'react-router-dom'
import { AccountPermissions } from '../constants/common'
import { ReactElement, useEffect, useState } from 'react'
import { LoadingSpinner } from '../components/shared/LoadingSpinner'
import { useProfileContext } from '../contexts/profileContext'
import { checkPermission } from '../utils/permissions'

interface Props {
  children: ReactElement
  requiredPermission: AccountPermissions
}

export function RouteGuard({ children, requiredPermission }: Props): ReactElement {
  const location = useLocation()
  const { profile } = useProfileContext()

  const [hasPermission, setHasPermission] = useState<boolean | null>(null)

  useEffect(() => {
    setHasPermission(checkPermission(requiredPermission, profile.Permissions))
  }, [])

  if (hasPermission == null) {
    return <LoadingSpinner isLoading={true} label="Loading..." />
  }

  return <>{hasPermission ? children : <Navigate to="/unauthorized" state={{ from: location }} replace />}</>
}
