import { Address, AddressRequest, Country, Region } from '../models/manager/address'
import { apiRequest } from '../utils/axios'

export async function fetchAddresses(accountId: number, token: string): Promise<AddressRequest[]> {
  return apiRequest('GET', `/addresses/${accountId}`, token)
}

export async function fetchAddress(addressId: number, token: string): Promise<Address> {
  return apiRequest('GET', `/address/${addressId}`, token)
}

export async function getAccountAddress(accountId: number, token: string): Promise<Address> {
  return apiRequest('GET', `/addressForAccount/${accountId}`, token)
}

export async function saveAddress(addressData: AddressRequest, token: string): Promise<number> {
  return apiRequest('POST', '/address', token, addressData)
}

export async function updateAddress(addressData: AddressRequest, token: string): Promise<void> {
  return apiRequest('PUT', '/address', token, addressData)
}

export async function addressSetDefault(addressId: number, token: string): Promise<void> {
  return apiRequest('PUT', `/addressSetDefault/${addressId}`, token)
}

export async function deleteAddress(addressId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/address/${addressId}`, token)
}

export async function fetchCountries(token: string): Promise<Country[]> {
  return apiRequest('GET', `/countries/`, token)
}

export async function fetchRegionByCountryId(countryId: number, token: string): Promise<Region[]> {
  return apiRequest('GET', `/regions/${countryId}`, token)
}
