import {
  Shipment,
  ShippingPurchaseRequest,
  ShippingPurchaseResponse,
  ShippingRateRequest,
  ShippingRateResponse,
  ShippingTrackRequest,
  ShippingTrackResponse,
} from '../models/manager/shipping'
import { apiRequest } from '../utils/axios'

export async function fetchShippingRates(
  shippingRateRequest: ShippingRateRequest,
  token: string
): Promise<ShippingRateResponse> {
  return apiRequest('POST', '/shippingRates', token, shippingRateRequest)
}

export async function createShippingPurchase(
  shippingPurchaseRequest: ShippingPurchaseRequest,
  token: string
): Promise<ShippingPurchaseResponse> {
  return apiRequest('POST', '/shippingPurchase', token, shippingPurchaseRequest)
}

export async function trackShipping(
  shippingTrackRequest: ShippingTrackRequest,
  token: string
): Promise<ShippingTrackResponse> {
  return apiRequest('POST', '/shippingTrack', token, shippingTrackRequest)
}

export async function getShipmentByJobId(jobId: number, token: string): Promise<Shipment> {
  return apiRequest('GET', `/shipment/${jobId}`, token)
}

export async function saveShipment(shipment: Shipment, token: string): Promise<Shipment> {
  return apiRequest('POST', '/shipment', token, shipment)
}
