import { Shipment } from '../../models/manager/shipping'
import { apiRequest } from '../../utils/axios'
import { ShipmentDetails } from '../models/admin'

export async function fetchShipmentByJobId(jobId: number, token: string): Promise<Shipment[]> {
  return apiRequest('GET', `/admin/shipmentsByJobId/${jobId}`, token)
}

export async function fetchShipmentsByAccountId(accountId: number, token: string): Promise<ShipmentDetails[]> {
  return apiRequest('GET', `/admin/shipmentsByAccountId/${accountId}`, token)
}
