import { Skeleton, Table, Tag } from 'antd'
import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../../components/shared/LoadingSpinner'
import { errorHandler, statusColor, insertSpaceCamelCase } from '../../../utils/helper'
import { Job } from '../../../models/manager/job'
import { useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { fetchJobs } from '../../../services/jobs'
import { useAuth0 } from '@auth0/auth0-react'
import { Status } from '../../../constants/common'

interface Props {
  accountId: number
}

export default function AdminAccountJobListing({ accountId }: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const [filteredDataSource, setFilteredDataSource] = useState<Job[]>([])
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [jobsData, setJobsData] = useState<Job[]>([])

  const columns: ColumnsType<Job> = [
    {
      key: 'updated',
      title: 'Updated',
      dataIndex: 'UpdatedAt',
      sorter: (a, b) => (dayjs(a.UpdatedAt).isBefore(b.UpdatedAt) ? -1 : 1),
      render: (date: Date) => {
        return (
          <div className="flex flex-[1] flex-nowrap whitespace-nowrap not-italic font-normal pr-2 pl-2">
            {dayjs(date).format('YYYY-MM-DD')}
          </div>
        )
      },
      width: 90,
    },
    {
      key: 'id',
      title: 'Id',
      dataIndex: 'Id',
      width: 60,
      sorter: (a, b) => a.Id - b.Id,
      render: (id: number) => {
        return <div className="pr-2 pl-2">{id}</div>
      },
    },
    {
      key: 'name',
      title: 'Job Name',
      dataIndex: '',
      width: 150,
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (job: Job) => {
        return (
          <div className="flex flex-nowrap justify-start items-center gap-1">
            <div className="flex-[1] px-[8px] py-px gap-[4px] cursor-pointer flex-nowrap">
              <div className="not-italic font-normal pr-2 pl-2 overflow-hidden overflow-ellipsis w-[fit-content] whitespace-nowrap max-w-[25ch]">
                {job.Name}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      key: 'quantity',
      title: '#',
      dataIndex: '',
      sorter: (a, b) => a.RecordCount + (a.BlankStock || 0) - b.RecordCount + (b.BlankStock || 0),
      render: (job) => {
        return (
          <div className="pr-2 pl-2 justify-center self-center text-center">{job.RecordCount + job.BlankStock}</div>
        )
      },
      width: 60,
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'StatusId',
      width: 100,
      render: (sid) => (
        <div className="pr-2 pl-2 pt-1 pb-1">
          <Tag style={statusColor(sid)} key={sid}>
            {insertSpaceCamelCase(Status[sid])}
          </Tag>
        </div>
      ),
      sorter: (a, b) => a.StatusId - b.StatusId,
    },
  ]

  const onRowSelected = (job: Job, event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    event?.stopPropagation()
    navigate(`/admin/job-detail?jobId=${job.Id}`, { state: { job: job } })
  }

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      fetchJobs(accountId, token)
        .then((data) => {
          data.sort((a, b) => (dayjs(a.CreatedAt).isBefore(b.CreatedAt) ? 1 : -1))
          setJobsData(data)
          setFilteredDataSource(data)
        })
        .catch(errorHandler)
        .finally(() => setIsLoading(false))
    })
  }, [])

  useEffect(() => {
    const filteredData = jobsData.sort((a, b) => (dayjs(a.UpdatedAt).isBefore(b.UpdatedAt) ? 1 : -1))
    setFilteredDataSource(filteredData)
  }, [jobsData])

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      {isLoading && <LoadingSpinner isLoading={isLoading} label="Loading..." />}
      <div className="flex-col flex-wrap">
        <div className="adminContainerRow">
          <div className="adminContainer">
            <div id="adminAccountJobListing" className="mt-1">
              {isLoading ? (
                <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
              ) : (
                <Table<Job>
                  id="view-jobs"
                  pagination={{
                    pageSizeOptions: ['10', '20', '30', '50'],
                    showSizeChanger: true,
                  }}
                  bordered
                  className="adminTableRow"
                  rowKey={(record) => record.Id}
                  columns={columns}
                  size="small"
                  onRow={(record) => {
                    return {
                      onClick: (e) => {
                        onRowSelected(record, e)
                      },
                    }
                  }}
                  dataSource={filteredDataSource}
                  onHeaderRow={() => ({
                    className:
                      'text-[12px] !text-[#7E7E7E] not-italic !font-normal leading-[20px] tracking-wide text-left !bg-[white]',
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}
