import { AccountPermissions } from '../constants/common'
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8'

const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY ?? ''

export function getNumberForPermissions(permissions: AccountPermissions[]): number {
  let result = 0
  for (let x = 0; x < permissions.length; x++) {
    result |= permissions[x]
  }

  return result
}

export function getPermissionsFromNumber(permission: number): AccountPermissions[] {
  const result: AccountPermissions[] = []
  for (const key in AccountPermissions) {
    const value = parseInt(AccountPermissions[key])
    if ((permission & value) == value) {
      result.push(value)
    }
  }

  return result
}

export function checkPermission(permission: AccountPermissions, userPermissions: number): boolean {
  const result = (permission & userPermissions) == permission
  return result
}

export function encryptData(data: string): string {
  return CryptoAES.encrypt(data, STORAGE_KEY).toString()
}

export function decryptData(data: string): string {
  return CryptoAES.decrypt(data, STORAGE_KEY).toString(CryptoENC)
}
