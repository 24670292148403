import { ReactElement } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import EventDashboard from '../pages/events/EventDashboard'
import EventAdmin from '../pages/events/EventAdmin'
import EventKiosk from '../pages/events/EventKiosk'
import EventReports from '../pages/events/EventReports'
import EventIntegrations from '../pages/events/EventIntegrations'

export const EventsRoutes = (): ReactElement => {
  return (
    <Routes>
      <Route path="/*" element={<Navigate replace to="/events/dashboard" />} />
      <Route path="/dashboard" element={<EventDashboard />} />
      <Route path="/admin" element={<EventAdmin />} />
      <Route path="/kiosk" element={<EventKiosk />} />
      <Route path="/reports" element={<EventReports />} />
      <Route path="/integrations" element={<EventIntegrations />} />
    </Routes>
  )
}
