import { ReactElement } from 'react'

export function AppFooter(): ReactElement {
  const goToTermsConditions = () => {
    window.open('https://www.credsnow.com/termsandconditions', '_blank')
  }

  const goToPrivacyPolicy = () => {
    window.open('https://www.credsnow.com/privacypolicy', '_blank')
  }

  const goToCookiesPolicy = () => {
    window.open('https://www.credsnow.com/cookiepolicy', '_blank')
  }
  const goToShippingPolicy = () => {
    window.open('https://www.credsnow.com/shippingpolicy', '_blank')
  }
  const goToContactUs = () => {
    window.open('https://www.credsnow.com/contactus', '_blank')
  }

  return (
    <div className="w-full">
      <div className="max-w-[850px] m-auto">
        <div id="footer_wrapper">
          <div id="footer_terms" onClick={goToTermsConditions}>
            Terms & Conditions
          </div>
          <div id="footer_privacy" onClick={goToPrivacyPolicy}>
            Privacy Policy
          </div>
          <div id="footer_cookie" onClick={goToCookiesPolicy}>
            Cookie Policy
          </div>
          <div id="footer_shipping" onClick={goToShippingPolicy}>
            Shipping Policy
          </div>
          <div id="footer_contact" onClick={goToContactUs}>
            Contact Us
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full content-between mt-5 mb-[-17px]">
        <div className="text-[9pt] mr-auto text-[silver] self-start justify-start items-start text-left">
          © 2024 credsnow.com. All rights reserved.
        </div>
        <div>&nbsp;</div>
        <div className="text-[9pt] ml-auto text-[silver] self-end justify-end items-end text-right">
          Version {process.env.REACT_APP_VERSION}
        </div>
      </div>
    </div>
  )
}
