import { Fragment, ReactElement, ReactNode } from 'react'
import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { AuthHeader } from './AuthHeader'
import { LoadingSpinner } from '../../shared/LoadingSpinner'

interface Props {
  children?: ReactNode
  headless?: boolean
  isLoading?: boolean
}

export function AuthLayout({ children, headless, isLoading = false }: Props): ReactElement {
  const { Header } = Layout

  const layoutStyle = `mainLayout ${!headless ? 'min-h-screen' : ''}`
  const contentStyle = `bg-PrimaryDarkBlue-100 ${!headless ? 'min-h-screen' : ''}`

  return (
    <Fragment>
      <Layout className={layoutStyle}>
        {!headless && (
          <Header className="flex items-center">
            <AuthHeader />
          </Header>
        )}
        <Content className={contentStyle}>
          {isLoading && <LoadingSpinner isLoading={isLoading} label={'Loading...'} />}
          {children}
        </Content>
      </Layout>
    </Fragment>
  )
}
