import { ReactElement } from 'react'
import logo from '../../../assets/images/layout/cn_darkblue.svg'

export const AdminLogo = (): ReactElement => {
  return (
    <div id="logoWrapper" className="logo">
      <img
        id="logo"
        src={logo}
        className="logo"
        alt="logo"
        height="45.93"
        width="220"
        style={{ minWidth: '166px', cursor: 'pointer' }}
        onClick={() => {
          window.location.href = '/'
        }}
      />
    </div>
  )
}
