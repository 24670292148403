import { ReactElement, useEffect, useState } from 'react'
import { FieldSet } from '../../models/manager/fieldset'
import { Button, Input, Upload, message } from 'antd'
import { FieldType } from '../../constants/common'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload'
import { UploadOutlined } from '@ant-design/icons'
import { saveImageWithProgress } from '../../services/blobStorage'
import { getFileExtension, isValidImageUrl } from '../../utils/helper'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfileContext } from '../../contexts/profileContext'

interface Props {
  fieldSetData: FieldSet
  recordId: number
  onUpdateFieldData: (name: string, value: string) => void
  isSaved?: boolean
  value?: () => string
}

export function DynamicFieldsAdminPrint({
  fieldSetData,
  recordId,
  onUpdateFieldData,
  isSaved,
  value,
}: Props): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfileContext()

  const [inputValue, setInputValue] = useState<string | number | boolean | UploadFile>('')
  const [messageApi, contextHolder] = message.useMessage()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setInputValue(e.target.value)
    onUpdateFieldData(fieldSetData.Name, e.target.value)
  }

  const imageLinkFieldTest = (fieldname: string, url: string) => {
    if (url.length === 0) return
    if (!isValidImageUrl(url)) {
      messageApi.open({
        type: 'error',
        content:
          'The image URL is invalid. It must be a full URL to an image file (gif, jpg, jpeg, png, webp) beginning with http:// or https://',
        style: { marginTop: '15vh' },
      })
      const invalidImageLinkField = document.getElementsByName(fieldname) as NodeListOf<HTMLInputElement>
      if (invalidImageLinkField) {
        invalidImageLinkField[0].value = ''
        invalidImageLinkField[0].focus()
      }
      return
    }
  }

  // I use this function to allow only numbers and a couple more keys, InputNumber from AntDesign doesn't work as we expected
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key !== 'Enter' &&
      e.key !== 'Tab' &&
      (e.key < '0' || e.key > '9') &&
      e.keyCode !== 8 && // Backspace key
      e.keyCode !== 37 && // Left arrow key
      e.keyCode !== 39 // Right arrow key
    ) {
      e.preventDefault()
    }
  }

  const renderInput = () => {
    switch (fieldSetData.TypeId) {
      case FieldType.Text:
        return (
          <Input
            defaultValue={''}
            value={inputValue.toString()}
            placeholder={fieldSetData.Name}
            name={fieldSetData.Name}
            onChange={handleInputChange}
          />
        )
      case FieldType.ImageLink:
        return (
          <Input
            defaultValue={''}
            value={inputValue.toString()}
            placeholder={fieldSetData.Name}
            name={fieldSetData.Name}
            onBlur={(e) => imageLinkFieldTest(e.target.name, e.target.value)}
            onChange={handleInputChange}
          />
        )
      case FieldType.Number:
        return (
          <Input
            onKeyDown={handleKeyDown}
            defaultValue={''}
            value={inputValue.toString()}
            placeholder={fieldSetData.Name}
            name={fieldSetData.Name}
            onChange={handleInputChange}
          />
        )
      case FieldType.Date:
        return (
          <Input
            max="9999-12-31"
            type="date"
            defaultValue={''}
            value={inputValue.toString()}
            placeholder={fieldSetData.Name}
            name={fieldSetData.Name}
            onChange={handleInputChange}
          />
        )
      case FieldType.Image:
        return (
          <div className="flex flex-col">
            <Upload
              accept="image/png, image/jpeg, image/jpg, image/gif, image/webP, image/svg, image/ico"
              customRequest={(e) => {
                if (e.data) {
                  if (recordId == 0) {
                    e.data['storageName'] = `${(e.file as RcFile).name ?? ''}`
                  } else {
                    e.data['storageName'] = `${fieldSetData.Id}-${recordId}.${getFileExtension(
                      (e.file as RcFile).name ?? ''
                    )}`
                  }
                  e.data['folder'] = `${profile.AccountUid}/record-data/${fieldSetData.RecordSetId}`
                }
                getAccessTokenSilently().then((token) => {
                  saveImageWithProgress(e, token)
                })
              }}
              name="file"
              showUploadList={false}
              onChange={(info: UploadChangeParam) => {
                if (info.file.status === 'done') {
                  setInputValue(info.file.response.name)
                  onUpdateFieldData(fieldSetData.Name, info.file.response.name)
                  messageApi.open({
                    type: 'success',
                    content: `${info.file.name} file uploaded successfully`,
                    style: { marginTop: '15vh' },
                  })
                } else if (info.file.status === 'error') {
                  messageApi.open({
                    type: 'error',
                    content: `${info.file.name} file upload failed.`,
                    style: { marginTop: '15vh' },
                  })
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Image </Button>
            </Upload>
            <span className="my-1">{inputValue.toString()}</span>
          </div>
        )
      default:
        return (
          <Input
            value={inputValue.toString()}
            placeholder={fieldSetData.Name}
            name={fieldSetData.Name}
            onChange={handleInputChange}
          />
        )
    }
  }

  useEffect(() => {
    if (isSaved) {
      setInputValue('')
    }
  }, [isSaved])

  useEffect(() => {
    if (value) {
      setInputValue(value())
      onUpdateFieldData(fieldSetData.Name, value())
    }
  }, [])

  return (
    <div className="my-2 w-full flex flex-col gap-2 pr-10">
      {contextHolder}
      <label>
        {fieldSetData.IsRequired && <span className="text-ErrorPrimary-100 text-sm">*</span>}
        {fieldSetData.Name}
      </label>
      <div className="w-full">{renderInput()}</div>
    </div>
  )
}
