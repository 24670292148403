import { ReactElement, useEffect, useState } from 'react'
import { SiteButton } from '../../components/controls/SiteButton'
import { useNavigate } from 'react-router-dom'
import { Divider } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfileContext } from '../../contexts/profileContext'
import { EVENT_IMAGE_FOLDER } from '../../constants/common'
import { fetchFileAsDataUrl } from '../../services/blobStorage'
import { errorHandler } from '../../utils/helper'
import { Avatar } from 'antd'

interface Props {
  pageName: string
}

export function EventHeader({ pageName }: Props): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfileContext()
  const navigate = useNavigate()
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      fetchFileAsDataUrl(`${profile.AccountUid}/${EVENT_IMAGE_FOLDER}/${localStorage.getItem('eventId')}.*`, token)
        .then((data) => {
          setImageUrl(data.data)
        })
        .catch(errorHandler)
    })
  }, [])

  return (
    <>
      <div className="flex flex-row w-full mb-4">
        <div>
          {imageUrl ? (
            <Avatar size={64} src={<img src={imageUrl} />} />
          ) : (
            <Avatar size={64}>{localStorage.getItem('eventShortName')?.substring(0, 3)}</Avatar>
          )}
        </div>
        <div className="w-full ml-4">
          <div className="mb-1">
            <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">{pageName}</h1>
          </div>
          <div className="flex flex-row">
            <div className="text-black/90 text-base font-semibold font-['SF Pro Text'] leading-normal">
              {localStorage.getItem('eventName')}
              <span className="text-black/60 text-xs font-normal font-['SF Pro Text'] leading-tight ml-[15px]">
                {localStorage.getItem('eventDate')}
              </span>
            </div>
          </div>
        </div>
        <div>
          <SiteButton onClick={() => navigate('/events')} label={'Back'} id="close" />
        </div>
      </div>
      <div className="flex-col items-center bg-SecondaryBgWhite-100 rounded-lg px-4">
        <Divider className="my-2 bg-BorderLightGrey-100 ml-[-10px]" />
      </div>
    </>
  )
}
